import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { FaPlus, FaRegLightbulb, FaMedal, FaShare, FaChevronDown, FaChevronUp } from 'react-icons/fa';

// Mock function for notifications (replace with actual implementation)
const scheduleNotification = (message, date) => {
  console.log(`Notification scheduled: ${message} at ${date}`);
};

const commonGoals = [
  "Improve sleep habits",
  "Practice daily meditation",
  "Exercise regularly",
  "Reduce stress levels",
  "Improve social connections"
];

const commonHabits = [
  "Go to bed at a consistent time",
  "Meditate for 10 minutes",
  "Take a 30-minute walk",
  "Practice deep breathing exercises",
  "Call a friend or family member"
];

const GoalHabitTracker = () => {
  const [goals, setGoals] = useState([]);
  const [newGoal, setNewGoal] = useState({
    title: '',
    description: '',
    targetDate: '',
    habits: [],
    progress: [],
    recurring: 'none',
    reminderTime: ''
  });
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [rewards, setRewards] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  useEffect(() => {
    const savedGoals = JSON.parse(localStorage.getItem('mentalHealthGoals')) || [];
    setGoals(savedGoals);
    const savedRewards = JSON.parse(localStorage.getItem('mentalHealthRewards')) || [];
    setRewards(savedRewards);
  }, []);

  const saveGoals = (updatedGoals) => {
    setGoals(updatedGoals);
    localStorage.setItem('mentalHealthGoals', JSON.stringify(updatedGoals));
  };

  const saveRewards = (updatedRewards) => {
    setRewards(updatedRewards);
    localStorage.setItem('mentalHealthRewards', JSON.stringify(updatedRewards));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewGoal(prev => ({ ...prev, [name]: value }));
  };

  const addGoal = () => {
    if (newGoal.title.trim() === '' || newGoal.targetDate === '') return;
    const goalId = Date.now();
    const updatedGoals = [...goals, { ...newGoal, id: goalId, habits: [], progress: [] }];
    saveGoals(updatedGoals);
    setNewGoal({ 
      title: '', 
      description: '', 
      targetDate: '', 
      habits: [], 
      progress: [],
      recurring: 'none',
      reminderTime: ''
    });

    // Schedule reminder
    if (newGoal.reminderTime) {
      scheduleNotification(`Reminder for goal: ${newGoal.title}`, newGoal.reminderTime);
    }
  };

  const addHabit = (goalId, habitName) => {
    const updatedGoals = goals.map(goal => {
      if (goal.id === goalId) {
        return {
          ...goal,
          habits: [...goal.habits, { id: Date.now(), name: habitName, completed: false }]
        };
      }
      return goal;
    });
    saveGoals(updatedGoals);
  };

  const toggleHabit = (goalId, habitId) => {
    const updatedGoals = goals.map(goal => {
      if (goal.id === goalId) {
        const updatedHabits = goal.habits.map(habit => 
          habit.id === habitId ? { ...habit, completed: !habit.completed } : habit
        );
        return { ...goal, habits: updatedHabits };
      }
      return goal;
    });
    saveGoals(updatedGoals);

    // Check if all habits are completed
    const completedGoal = updatedGoals.find(g => g.id === goalId);
    if (completedGoal && completedGoal.habits.every(h => h.completed)) {
      addReward(goalId, "Completed all habits!");
    }
  };

  const updateProgress = (goalId, progressValue) => {
    const updatedGoals = goals.map(goal => {
      if (goal.id === goalId) {
        const newProgress = [...goal.progress, { date: new Date().toISOString(), value: progressValue }];
        return { ...goal, progress: newProgress };
      }
      return goal;
    });
    saveGoals(updatedGoals);

    // Check if goal is completed
    const updatedGoal = updatedGoals.find(g => g.id === goalId);
    if (updatedGoal && progressValue >= 100) {
      addReward(goalId, "Goal completed!");
    }
  };

  const addReward = (goalId, message) => {
    const newReward = { id: Date.now(), goalId, message, date: new Date().toISOString() };
    const updatedRewards = [...rewards, newReward];
    saveRewards(updatedRewards);
  };

  const shareProgress = (goalId) => {
    const goal = goals.find(g => g.id === goalId);
    if (!goal) return;

    const shareText = `I'm making progress on my goal: "${goal.title}"! Current progress: ${goal.progress[goal.progress.length - 1]?.value}%`;
    
    // For demonstration, we'll just log the share text
    console.log("Shared progress:", shareText);
    // In a real app, you might open a share dialog or send an email here
  };

  return (
    <div className="container mx-auto p-4 max-w-6xl">
      <h1 className="text-3xl font-bold mb-8 text-center text-gray-800">Goal Setting and Habit Tracker</h1>
      
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-2xl font-semibold mb-4 text-gray-700">Set a New Goal</h2>
        <div className="space-y-4">
          <input
            type="text"
            name="title"
            value={newGoal.title}
            onChange={handleInputChange}
            placeholder="Goal title"
            className="w-full p-3 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
          <textarea
            name="description"
            value={newGoal.description}
            onChange={handleInputChange}
            placeholder="Goal description"
            className="w-full p-3 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            rows="3"
          />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <input
              type="date"
              name="targetDate"
              value={newGoal.targetDate}
              onChange={handleInputChange}
              className="w-full p-3 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            />
            <select
              name="recurring"
              value={newGoal.recurring}
              onChange={handleInputChange}
              className="w-full p-3 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            >
              <option value="none">Non-recurring</option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
            </select>
          </div>
          <input
            type="time"
            name="reminderTime"
            value={newGoal.reminderTime}
            onChange={handleInputChange}
            className="w-full p-3 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
          <div className="flex space-x-2">
            <button
              onClick={addGoal}
              className="flex-grow bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300 flex items-center justify-center"
            >
              <FaPlus className="mr-2" /> Add Goal
            </button>
            <button
              onClick={() => setShowSuggestions(!showSuggestions)}
              className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition duration-300 flex items-center justify-center"
            >
              <FaRegLightbulb className="mr-2" /> {showSuggestions ? 'Hide' : 'Show'} Suggestions
            </button>
          </div>
        </div>
        
        {showSuggestions && (
          <div className="mt-4 bg-gray-50 p-4 rounded-md">
            <h3 className="font-semibold mb-2">Common Goals:</h3>
            <ul className="space-y-1 mb-4">
              {commonGoals.map((goal, index) => (
                <li 
                  key={index} 
                  className="cursor-pointer text-blue-500 hover:text-blue-700 transition duration-300"
                  onClick={() => setNewGoal(prev => ({ ...prev, title: goal }))}
                >
                  {goal}
                </li>
              ))}
            </ul>
            <h3 className="font-semibold mb-2">Common Habits:</h3>
            <ul className="space-y-1">
              {commonHabits.map((habit, index) => (
                <li 
                  key={index} 
                  className="cursor-pointer text-blue-500 hover:text-blue-700 transition duration-300"
                  onClick={() => setNewGoal(prev => ({ ...prev, habits: [...prev.habits, habit] }))}
                >
                  {habit}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <div className="space-y-6">
        <h2 className="text-2xl font-semibold mb-4 text-gray-700">Your Goals</h2>
        {goals.map(goal => (
          <div key={goal.id} className="bg-white rounded-lg shadow-md p-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold text-gray-800">{goal.title}</h3>
              <div className="space-x-2">
                <button
                  onClick={() => setSelectedGoal(goal.id === selectedGoal ? null : goal.id)}
                  className="text-blue-500 hover:text-blue-700 transition duration-300"
                >
                  {goal.id === selectedGoal ? <FaChevronUp /> : <FaChevronDown />}
                </button>
                <button
                  onClick={() => shareProgress(goal.id)}
                  className="text-green-500 hover:text-green-700 transition duration-300"
                >
                  <FaShare />
                </button>
              </div>
            </div>
            <p className="text-gray-600 mb-2">{goal.description}</p>
            <p className="text-sm text-gray-500">Target Date: {new Date(goal.targetDate).toLocaleDateString()}</p>
            <p className="text-sm text-gray-500">Recurring: {goal.recurring}</p>
            
            {goal.id === selectedGoal && (
              <div className="mt-4 space-y-4">
                <div>
                  <h4 className="font-semibold mb-2">Habits:</h4>
                  <ul className="space-y-2">
                    {goal.habits.map(habit => (
                      <li key={habit.id} className="flex items-center">
                        <input
                          type="checkbox"
                          checked={habit.completed}
                          onChange={() => toggleHabit(goal.id, habit.id)}
                          className="mr-2"
                        />
                        <span className={habit.completed ? 'line-through text-gray-500' : ''}>{habit.name}</span>
                      </li>
                    ))}
                  </ul>
                  <input
                    type="text"
                    placeholder="New habit"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        addHabit(goal.id, e.target.value);
                        e.target.value = '';
                      }
                    }}
                    className="w-full p-2 mt-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  />
                </div>
                <div>
                  <h4 className="font-semibold mb-2">Update Progress:</h4>
                  <input
                    type="number"
                    min="0"
                    max="100"
                    placeholder="Progress (%)"
                    onBlur={(e) => {
                      if (e.target.value) {
                        updateProgress(goal.id, parseInt(e.target.value));
                        e.target.value = '';
                      }
                    }}
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  />
                </div>
                <div className="h-64">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={goal.progress.map(p => ({ date: new Date(p.date), value: p.value }))}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" tickFormatter={(date) => date.toLocaleDateString()} />
                      <YAxis />
                      <Tooltip labelFormatter={(date) => date.toLocaleDateString()} />
                      <Legend />
                      <Line type="monotone" dataKey="value" stroke="#8884d8" name="Progress" />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="mt-8 bg-white rounded-lg shadow-md p-6">
        <h2 className="text-2xl font-semibold mb-4 text-gray-700">Rewards and Achievements</h2>
        <div className="space-y-4">
          {rewards.map(reward => (
            <div key={reward.id} className="bg-yellow-100 rounded-md p-4 flex items-center">
              <FaMedal className="text-yellow-500 mr-4 text-2xl" />
              <div>
                <p className="font-semibold">{reward.message}</p>
                <p className="text-sm text-gray-600">
                  {new Date(reward.date).toLocaleString()} - 
                  Goal: {goals.find(g => g.id === reward.goalId)?.title}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GoalHabitTracker;