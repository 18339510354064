import React from 'react';
import { UserButton, useUser, useClerk } from "@clerk/clerk-react";
import { LogOut } from 'lucide-react';

const UserProfileSection = ({ handleSignOut }) => {
  const { user } = useUser();
  const { openUserProfile } = useClerk();

  return (
    <div className="flex items-center">
      <UserButton
        appearance={{
          elements: {
            userButtonAvatarBox: "w-9 h-9",
            userButtonTrigger: "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          }
        }}
      />
      <div 
        className="ml-3 flex-grow cursor-pointer"
        onClick={() => openUserProfile()}
      >
        <p className="text-sm font-medium text-gray-700 hover:text-gray-900">
          {user?.username}
        </p>
      </div>
      <button
        onClick={handleSignOut}
        className="ml-2 p-1 text-gray-400 hover:text-gray-500"
      >
        <LogOut className="h-5 w-5" />
      </button>
    </div>
  );
};

export default UserProfileSection;