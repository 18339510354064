import React from 'react';
import ttmeLogo from '../assets/ttmeLogo.png';
import ReactPlayer from 'react-player/youtube';
import appThumbNail from '../assets/appThumbNail.png'
import { SignInButton, SignedOut } from '@clerk/clerk-react';
import { Link } from 'react-router-dom';


const Home = () => {
  return (
    <div className="bg-white min-h-screen">
      <div className="container px-4">
        <div className="bg-white rounded-lg overflow-hidden">
          <div className="md:flex">
            <div className="md:w-1/2">
              <img
                src={ttmeLogo}
                alt="Mental Health App Logo"
                className="w-52 mx-auto mb-8"
              />
              <h1 className="text-3xl font-bold text-gray-800 mb-4 text-center md:text-left">
                Welcome To Your Self-Care Safe Space
              </h1>
            
              <div className="hidden md:flex flex-wrap justify-center md:justify-start gap-4">
              <SignedOut>
            
          </SignedOut>
                <Link
                  to="/mood-tracker"
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300"
                >
                  Start Tracking Your Mood
                </Link>
                <Link
                  to="/resource-library"
                  className="bg-purple-500 hover:bg-purple-600 text-white font-bold py-2 px-4 rounded transition duration-300"
                >
                  Explore Resources
                </Link>
              </div>
            </div>
          
            <div className=' md:w-1/2 p-6 flex items-center justify-center'>
              <div className='w-full aspect-w-16 aspect-h-9'>
                <ReactPlayer 
                url='https://youtu.be/eRTIBaJ10Tsmode=opaque&rel=0&autohide=1&showinfo=0&wmode=transparent'
                width='100%'
                height='100%'
                controls ='true'
                light={<img src={appThumbNail} alt='Thumbnail' />}
                />
              </div>
            </div>
          </div>
        </div>
        <SignedOut>
            <div className="md:hidden flex flex-shrink-0  border-gray-200 p-4">
            
              <SignInButton className="w-full text-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-custom-teal hover:bg-custom-teal-dark" />
            </div>
          </SignedOut>
        <div className="hidden mt-12 md:grid md:grid-cols-3 gap-8">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4 text-blue-600">Track Your Mood</h2>
            <p className="text-gray-600">Log your daily emotions and identify patterns to better understand your mental health journey.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4 text-purple-600">Set Goals</h2>
            <p className="text-gray-600">Create and track personal goals to foster positive habits and promote self-improvement.</p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4 text-green-600">Connect with Others</h2>
            <p className="text-gray-600">Join our supportive community to share experiences and find encouragement on your mental health journey.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;