import React, { useState } from 'react';
import { Calendar as CalendarIcon, ChevronLeft, ChevronRight } from 'lucide-react';

const DateRangePicker = ({ value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (newDate) => {
    if (!value.from || (value.from && value.to)) {
      onChange({ from: newDate, to: null });
    } else if (newDate < value.from) {
      onChange({ from: newDate, to: value.from });
    } else {
      onChange({ ...value, to: newDate });
    }
    if (value.from && newDate > value.from) {
      setIsOpen(false);
    }
  };

  const generateCalendarDays = (year, month) => {
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const days = [];
    for (let i = 1; i <= lastDay.getDate(); i++) {
      days.push(new Date(year, month, i));
    }
    return days;
  };

  const isDateInRange = (date) => {
    if (!value.from || !value.to) return false;
    return date >= value.from && date <= value.to;
  };

  const isDateSelected = (date) => {
    return date.toDateString() === value.from?.toDateString() || date.toDateString() === value.to?.toDateString();
  };

  const currentDate = new Date();
  const [displayMonth, setDisplayMonth] = useState(currentDate.getMonth());
  const [displayYear, setDisplayYear] = useState(currentDate.getFullYear());

  const changeMonth = (increment) => {
    let newMonth = displayMonth + increment;
    let newYear = displayYear;
    if (newMonth > 11) {
      newMonth = 0;
      newYear++;
    } else if (newMonth < 0) {
      newMonth = 11;
      newYear--;
    }
    setDisplayMonth(newMonth);
    setDisplayYear(newYear);
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full px-4 py-2 text-left bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <CalendarIcon className="inline-block w-5 h-5 mr-2" />
        {value.from ? (
          value.to ? (
            `${value.from.toDateString()} - ${value.to.toDateString()}`
          ) : (
            value.from.toDateString()
          )
        ) : (
          <span>Pick a date range</span>
        )}
      </button>
      {isOpen && (
        <div className="absolute z-10 mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
          <div className="flex justify-between items-center p-2 border-b">
            <button onClick={() => changeMonth(-1)} className="p-1"><ChevronLeft /></button>
            <span>{new Date(displayYear, displayMonth).toLocaleString('default', { month: 'long', year: 'numeric' })}</span>
            <button onClick={() => changeMonth(1)} className="p-1"><ChevronRight /></button>
          </div>
          <div className="grid grid-cols-7 gap-1 p-2">
            {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map(day => (
              <div key={day} className="text-center text-sm font-semibold">{day}</div>
            ))}
            {generateCalendarDays(displayYear, displayMonth).map((date, index) => (
              <button
                key={index}
                onClick={() => handleSelect(date)}
                className={`w-8 h-8 text-center rounded-full ${
                  isDateSelected(date)
                    ? 'bg-blue-500 text-white'
                    : isDateInRange(date)
                    ? 'bg-blue-100'
                    : 'hover:bg-gray-100'
                }`}
              >
                {date.getDate()}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangePicker ;