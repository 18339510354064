import React, { useState, useEffect } from 'react';
import ThoughtRecordTutorial from './ThoughtRecordTutorial';
import { FaQuestionCircle, FaSave, FaChevronDown, FaChevronUp } from 'react-icons/fa';

const ThoughtRecordTool = () => {
  const [records, setRecords] = useState([]);
  const [currentRecord, setCurrentRecord] = useState({
    situation: '',
    automaticThoughts: '',
    emotions: '',
    evidenceFor: '',
    evidenceAgainst: '',
    balancedThought: '',
    outcomeEmotion: ''
  });
  const [showTutorial, setShowTutorial] = useState(false);
  const [expandedRecord, setExpandedRecord] = useState(null);

  useEffect(() => {
    const savedRecords = JSON.parse(localStorage.getItem('thoughtRecords')) || [];
    setRecords(savedRecords);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentRecord(prev => ({ ...prev, [name]: value }));
  };

  const saveRecord = () => {
    const newRecord = { ...currentRecord, id: Date.now(), date: new Date().toLocaleString() };
    const updatedRecords = [newRecord, ...records];
    setRecords(updatedRecords);
    localStorage.setItem('thoughtRecords', JSON.stringify(updatedRecords));
    setCurrentRecord({
      situation: '',
      automaticThoughts: '',
      emotions: '',
      evidenceFor: '',
      evidenceAgainst: '',
      balancedThought: '',
      outcomeEmotion: ''
    });
  };

  const toggleRecordExpansion = (id) => {
    setExpandedRecord(expandedRecord === id ? null : id);
  };

  return (
    <div className="container mx-auto p-4 max-w-4xl">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-gray-800">Thought Record Tool</h1>
        <button
          onClick={() => setShowTutorial(true)}
          className="bg-blue-500 text-white px-4 py-2 rounded-full hover:bg-blue-600 transition duration-300 flex items-center"
        >
          <FaQuestionCircle className="mr-2" /> How to Use
        </button>
      </div>

      {showTutorial && <ThoughtRecordTutorial onClose={() => setShowTutorial(false)} />}

      <div className="bg-white shadow-md rounded-lg p-6 mb-8">
        <h2 className="text-xl font-semibold mb-4 text-gray-700">New Thought Record</h2>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Situation:</label>
            <textarea
              name="situation"
              value={currentRecord.situation}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder="Describe the situation that led to the negative emotion"
              rows="3"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Automatic Thoughts:</label>
            <textarea
              name="automaticThoughts"
              value={currentRecord.automaticThoughts}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder="What thoughts went through your mind?"
              rows="3"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Emotions:</label>
            <input
              type="text"
              name="emotions"
              value={currentRecord.emotions}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder="What emotions did you feel? (e.g., sad, anxious, angry)"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Evidence For:</label>
            <textarea
              name="evidenceFor"
              value={currentRecord.evidenceFor}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder="What evidence supports your automatic thought?"
              rows="3"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Evidence Against:</label>
            <textarea
              name="evidenceAgainst"
              value={currentRecord.evidenceAgainst}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder="What evidence does not support your automatic thought?"
              rows="3"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Balanced Thought:</label>
            <textarea
              name="balancedThought"
              value={currentRecord.balancedThought}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder="What's a more balanced way to think about this situation?"
              rows="3"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Outcome Emotion:</label>
            <input
              type="text"
              name="outcomeEmotion"
              value={currentRecord.outcomeEmotion}
              onChange={handleInputChange}
              className="w-full p-2 border rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder="How do you feel now?"
            />
          </div>
        </div>
        <button
          onClick={saveRecord}
          className="mt-6 bg-green-500 text-white px-6 py-2 rounded-md hover:bg-green-600 transition duration-300 flex items-center justify-center w-full"
        >
          <FaSave className="mr-2" /> Save Thought Record
        </button>
      </div>

      <div className="mt-8">
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Previous Thought Records</h2>
        {records.map(record => (
          <div key={record.id} className="mb-4 bg-white shadow-md rounded-lg overflow-hidden">
            <div 
              className="p-4 bg-gray-50 flex justify-between items-center cursor-pointer"
              onClick={() => toggleRecordExpansion(record.id)}
            >
              <div>
                <p className="font-semibold text-gray-800">{record.situation}</p>
                <p className="text-sm text-gray-600">{record.date}</p>
              </div>
              {expandedRecord === record.id ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {expandedRecord === record.id && (
              <div className="p-4 space-y-2">
                <p><strong className="text-gray-700">Automatic Thoughts:</strong> {record.automaticThoughts}</p>
                <p><strong className="text-gray-700">Emotions:</strong> {record.emotions}</p>
                <p><strong className="text-gray-700">Evidence For:</strong> {record.evidenceFor}</p>
                <p><strong className="text-gray-700">Evidence Against:</strong> {record.evidenceAgainst}</p>
                <p><strong className="text-gray-700">Balanced Thought:</strong> {record.balancedThought}</p>
                <p><strong className="text-gray-700">Outcome Emotion:</strong> {record.outcomeEmotion}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ThoughtRecordTool;