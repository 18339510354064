import React, { useState, useEffect } from 'react';
import ResourceSubmission from './ResourceSubmission';
import { FaStar, FaRegStar, FaBookmark, FaRegBookmark, FaComments } from 'react-icons/fa';

// Mock data for resources
const initialResources = [
  {
    id: 1,
    title: "Understanding Anxiety",
    description: "Learn about the symptoms, causes, and treatments for anxiety disorders.",
    type: "article",
    category: "Anxiety",
    content: "Anxiety is a normal human emotion...",
    url: "https://example.com/anxiety",
    rating: 4.5,
    ratingCount: 120,
    comments: [
      { id: 1, user: "JohnDoe", text: "This article really helped me understand my anxiety better.", approved: true },
      { id: 2, user: "JaneSmith", text: "Great resource! I'd love to see more like this.", approved: true }
    ],
    approved: true
  },
  {
    id: 2,
    title: "Mindfulness Meditation Guide",
    description: "A step-by-step guide to practicing mindfulness meditation.",
    type: "video",
    category: "Stress Management",
    content: "https://example.com/mindfulness-video",
    url: "https://example.com/mindfulness",
    rating: 4.8,
    ratingCount: 250,
    comments: [
      { id: 3, user: "MeditationFan", text: "This guide is perfect for beginners!", approved: true }
    ],
    approved: true
  },
  {
    id: 3,
    title: "Cognitive Behavioral Therapy Techniques",
    description: "Explore common CBT techniques for managing negative thoughts.",
    type: "infographic",
    category: "Therapy",
    content: "https://example.com/cbt-infographic.jpg",
    url: "https://example.com/cbt-techniques",
    rating: 4.2,
    ratingCount: 80,
    comments: [],
    approved: true
  },
  // Add more mock resources here
];

const ITEMS_PER_PAGE = 9;

const ResourceLibrary = () => {
  const [resources, setResources] = useState(initialResources);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [savedResources, setSavedResources] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showSubmissionForm, setShowSubmissionForm] = useState(false);
  const [userRole, setUserRole] = useState('user');
  const [newComment, setNewComment] = useState('');
  const [selectedResource, setSelectedResource] = useState(null);

  useEffect(() => {
    // In real app, fetch resources from an API here
    setResources(initialResources);

    // Load saved resources from localStorage
    const saved = JSON.parse(localStorage.getItem('savedResources')) || [];
    setSavedResources(saved);

    // Simulating user role assignment
    setUserRole(Math.random() > 0.8 ? 'admin' : 'user');
    const savedRole = localStorage.getItem('userRole');
    if (savedRole) {
        setUserRole(savedRole);
    } else {
        const newRole = Math.random() > 0.8 ? 'admin' : 'user';
        setUserRole(newRole);
        localStorage.setItem('userRole', newRole);
    }
  }, []);

  const categories = [...new Set(resources.map(resource => resource.category))];

  const handleSaveResource = (resourceId) => {
    const updatedSaved = savedResources.includes(resourceId)
      ? savedResources.filter(id => id !== resourceId)
      : [...savedResources, resourceId];
    
    setSavedResources(updatedSaved);
    localStorage.setItem('savedResources', JSON.stringify(updatedSaved));
  };

  const handleRateResource = (resourceId, rating) => {
    const updatedResources = resources.map(resource => {
      if (resource.id === resourceId) {
        const newRatingCount = resource.ratingCount + 1;
        const newRating = ((resource.rating * resource.ratingCount) + rating) / newRatingCount;
        return { ...resource, rating: newRating, ratingCount: newRatingCount };
      }
      return resource;
    });
    setResources(updatedResources);
    // In real app, send this rating to backend
  };

  const handleResourceSubmission = (newResource) => {
    const resourceWithDefaults = {
      ...newResource,
      id: Date.now(),
      rating: 0,
      ratingCount: 0,
      comments: [],
      approved: false
    };
    setResources(prev => [resourceWithDefaults, ...prev]);
    setShowSubmissionForm(false);
    // In real app, send this to backend for review before publishing
  };

  const handleCommentSubmit = (resourceId) => {
    if (newComment.trim() === '') return;

    const updatedResources = resources.map(resource => {
      if (resource.id === resourceId) {
        return {
          ...resource,
          comments: [
            ...resource.comments,
            { id: Date.now(), user: "CurrentUser", text: newComment, approved: false }
          ]
        };
      }
      return resource;
    });

    setResources(updatedResources);
    setNewComment('');
    // In real app, send this comment to backend for moderation
  };

  const handleModerateComment = (resourceId, commentId, approve) => {
    const updatedResources = resources.map(resource => {
      if (resource.id === resourceId) {
        if (approve) {
          // If approving, update the comment's approved status
          return {
            ...resource,
            comments: resource.comments.map(comment => 
              comment.id === commentId ? { ...comment, approved: true } : comment
            )
          };
        } else {
          // If rejecting, remove the comment
          return {
            ...resource,
            comments: resource.comments.filter(comment => comment.id !== commentId)
          };
        }
      }
      return resource;
    });

    setResources(updatedResources);
    // In real app, send this moderation action to backend
  };

  const handleModerateResource = (resourceId, approve) => {
    const updatedResources = resources.map(resource => 
      resource.id === resourceId ? { ...resource, approved: approve } : resource
    );

    setResources(updatedResources);
    // In real app, send this moderation action to backend
  };

  const filteredResources = resources.filter(resource => 
    resource.approved &&
    (resource.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
     resource.description.toLowerCase().includes(searchTerm.toLowerCase())) &&
    (selectedCategory === '' || resource.category === selectedCategory)
  );

  const pageCount = Math.ceil(filteredResources.length / ITEMS_PER_PAGE);
  const paginatedResources = filteredResources.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <div className="container mx-auto p-4 max-w-7xl">
      <h1 className="text-3xl font-bold mb-8 text-center text-gray-800">Mental Health Resource Library</h1>
      
      <div className="mb-6 flex justify-between items-center">
        <button
          onClick={() => {
            const newRole = userRole === 'admin' ? 'user' : 'admin';
            setUserRole(newRole);
            localStorage.setItem('userRole', newRole);
          }}
          className="px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-600 transition duration-300"
        >
          Toggle Role (Current: {userRole})
        </button>

        {userRole === 'admin' && (
          <button
            onClick={() => setShowSubmissionForm(!showSubmissionForm)}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition duration-300"
          >
            {showSubmissionForm ? 'Cancel Submission' : 'Submit New Resource'}
          </button>
        )}
      </div>

      {showSubmissionForm && (
        <div className="mb-8">
          <ResourceSubmission onSubmit={handleResourceSubmission} />
        </div>
      )}

      <div className="mb-6 flex flex-col md:flex-row gap-4">
        <input
          type="text"
          placeholder="Search resources..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          className="p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="">All Categories</option>
          {categories.map(category => (
            <option key={category} value={category}>{category}</option>
          ))}
        </select>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {paginatedResources.map(resource => (
          <div key={resource.id} className="bg-white rounded-lg shadow-md overflow-hidden transition duration-300 hover:shadow-lg">
            <div className="p-6">
              <h3 className="text-xl font-semibold mb-2 text-gray-800">{resource.title}</h3>
              <p className="text-sm text-gray-600 mb-2">{resource.category}</p>
              <p className="text-gray-700 mb-4">{resource.description}</p>
              <div className="flex justify-between items-center mb-4">
                <div className="flex items-center">
                  <span className="text-yellow-500 mr-1">{resource.rating.toFixed(1)}</span>
                  {[1, 2, 3, 4, 5].map(star => (
                    <button
                      key={star}
                      onClick={() => handleRateResource(resource.id, star)}
                      className="text-yellow-500 focus:outline-none"
                    >
                      {star <= Math.round(resource.rating) ? <FaStar /> : <FaRegStar />}
                    </button>
                  ))}
                  <span className="text-gray-600 text-sm ml-2">({resource.ratingCount})</span>
                </div>
                <button
                  onClick={() => handleSaveResource(resource.id)}
                  className="text-blue-500 hover:text-blue-600 focus:outline-none"
                >
                  {savedResources.includes(resource.id) ? <FaBookmark /> : <FaRegBookmark />}
                </button>
              </div>
              <div className="flex justify-between items-center">
                <a
                  href={resource.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  View Resource
                </a>
                <button
                  onClick={() => setSelectedResource(resource.id)}
                  className="flex items-center text-gray-600 hover:text-gray-800"
                >
                  <FaComments className="mr-1" />
                  <span>{resource.comments.filter(c => c.approved).length}</span>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-8 flex justify-center">
        <button
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="px-4 py-2 mr-2 bg-blue-500 text-white rounded disabled:bg-gray-300 transition duration-300"
        >
          Previous
        </button>
        <span className="px-4 py-2">
          Page {currentPage} of {pageCount}
        </span>
        <button
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, pageCount))}
          disabled={currentPage === pageCount}
          className="px-4 py-2 ml-2 bg-blue-500 text-white rounded disabled:bg-gray-300 transition duration-300"
        >
          Next
        </button>
      </div>

      {/* Comments Modal */}
      {selectedResource && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="relative p-8 border w-full max-w-xl shadow-lg rounded-lg bg-white">
            <h3 className="text-2xl font-semibold mb-6">Comments</h3>
            <div className="max-h-96 overflow-y-auto mb-6">
              {resources.find(r => r.id === selectedResource).comments
                .filter(c => c.approved || userRole === 'admin')
                .map(comment => (
                  <div key={comment.id} className="mb-4 p-4 bg-gray-100 rounded">
                    <p className="font-semibold">{comment.user}</p>
                    <p className="text-gray-700">{comment.text}</p>
                    {!comment.approved && userRole === 'admin' && (
                      <p className="text-sm text-red-500 mt-2">Pending Approval</p>
                    )}
                  </div>
                ))
              }
            </div>
            <textarea
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              placeholder="Add a comment..."
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows="3"
            />
            <div className="mt-4 flex justify-end">
              <button
                onClick={() => handleCommentSubmit(selectedResource)}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300 mr-2"
              >
                Submit Comment
              </button>
              <button
                onClick={() => setSelectedResource(null)}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 transition duration-300"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Moderation Panel (only visible to moderators/admins) */}
      {userRole === 'admin' && (
        <div className="mt-12 p-6 border rounded-lg bg-white shadow-md">
          <h3 className="text-2xl font-semibold mb-6">Moderation Panel</h3>
          {resources.filter(r => !r.approved || r.comments.some(c => !c.approved)).map(resource => (
            <div key={resource.id} className="mb-6 p-4 bg-gray-100 rounded-lg">
              <h4 className="text-lg font-semibold mb-2">{resource.title}</h4>
              {!resource.approved && (
                <div className="mt-2 mb-4">
                  <button
                    onClick={() => handleModerateResource(resource.id, true)}
                    className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition duration-300 mr-2"
                  >
                    Approve Resource
                  </button>
                  <button
                    onClick={() => handleModerateResource(resource.id, false)}
                    className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition duration-300"
                  >
                    Reject Resource
                  </button>
                </div>
              )}
              {resource.comments.filter(c => !c.approved).map(comment => (
                <div key={comment.id} className="mt-4 p-4 bg-white rounded-lg shadow">
                  <p className="mb-2"><strong>{comment.user}:</strong> {comment.text}</p>
                  <button
                    onClick={() => handleModerateComment(resource.id, comment.id, true)}
                    className="px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600 transition duration-300 mr-2"
                  >
                    Approve Comment
                  </button>
                  <button
                    onClick={() => handleModerateComment(resource.id, comment.id, false)}
                    className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 transition duration-300"
                  >
                    Delete Comment
                  </button>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ResourceLibrary;