import React, { useState, useEffect } from 'react';
import { ChevronRight, ChevronLeft, Info } from 'lucide-react';
import CognitiveRestructuringTutorial from './CognitiveRestructuringTutorial';

const ProgressBar = ({ currentStep, totalSteps }) => {
  const progress = (currentStep / totalSteps) * 100;
  
  return (
    <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
      <div 
        className="bg-blue-600 h-2.5 rounded-full transition-all duration-300 ease-in-out" 
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

const CognitiveRestructuringExercise = () => {
  const [exercises, setExercises] = useState([]);
  const [currentExercise, setCurrentExercise] = useState({
    negativeThought: '',
    cognitiveDistortions: [],
    challengingQuestions: '',
    reframedThought: ''
  });
  const [step, setStep] = useState(1);
  const [showTutorial, setShowTutorial] = useState(false);

  const totalSteps = 4;

  useEffect(() => {
    const savedExercises = JSON.parse(localStorage.getItem('cognitiveExercises')) || [];
    setExercises(savedExercises);
  }, []);

  const cognitiveDistortions = [
    'All-or-Nothing Thinking',
    'Overgeneralization',
    'Mental Filter',
    'Discounting the Positive',
    'Jumping to Conclusions',
    'Magnification or Minimization',
    'Emotional Reasoning',
    'Should Statements',
    'Labeling',
    'Personalization'
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentExercise(prev => ({ ...prev, [name]: value }));
  };

  const handleDistortionToggle = (distortion) => {
    setCurrentExercise(prev => ({
      ...prev,
      cognitiveDistortions: prev.cognitiveDistortions.includes(distortion)
        ? prev.cognitiveDistortions.filter(d => d !== distortion)
        : [...prev.cognitiveDistortions, distortion]
    }));
  };

  const saveExercise = () => {
    const newExercise = { ...currentExercise, id: Date.now() };
    const updatedExercises = [newExercise, ...exercises];
    setExercises(updatedExercises);
    localStorage.setItem('cognitiveExercises', JSON.stringify(updatedExercises));
    setCurrentExercise({
      negativeThought: '',
      cognitiveDistortions: [],
      challengingQuestions: '',
      reframedThought: ''
    });
    setStep(1);
  };

  const renderStep = () => {
    switch(step) {
      case 1:
        return (
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Step 1: Identify the Negative Thought</h3>
            <textarea
              name="negativeThought"
              value={currentExercise.negativeThought}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              placeholder="Write down your negative thought"
              rows="4"
            />
            <button onClick={() => setStep(2)} className="w-full bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300 flex items-center justify-center">
              Next <ChevronRight className="ml-2 h-5 w-5" />
            </button>
          </div>
        );
      case 2:
        return (
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Step 2: Identify Cognitive Distortions</h3>
            <div className="grid grid-cols-2 gap-2">
              {cognitiveDistortions.map(distortion => (
                <button
                  key={distortion}
                  onClick={() => handleDistortionToggle(distortion)}
                  className={`p-2 border rounded-md transition duration-300 ${
                    currentExercise.cognitiveDistortions.includes(distortion)
                      ? 'bg-blue-100 border-blue-500 text-blue-700'
                      : 'bg-white border-gray-300 hover:bg-gray-50'
                  }`}
                >
                  {distortion}
                </button>
              ))}
            </div>
            <div className="flex justify-between">
              <button onClick={() => setStep(1)} className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600 transition duration-300 flex items-center">
                <ChevronLeft className="mr-2 h-5 w-5" /> Back
              </button>
              <button onClick={() => setStep(3)} className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300 flex items-center">
                Next <ChevronRight className="ml-2 h-5 w-5" />
              </button>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Step 3: Challenge the Thought</h3>
            <textarea
              name="challengingQuestions"
              value={currentExercise.challengingQuestions}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              placeholder="What evidence contradicts this thought? Is there another way to look at this situation?"
              rows="4"
            />
            <div className="flex justify-between">
              <button onClick={() => setStep(2)} className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600 transition duration-300 flex items-center">
                <ChevronLeft className="mr-2 h-5 w-5" /> Back
              </button>
              <button onClick={() => setStep(4)} className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300 flex items-center">
                Next <ChevronRight className="ml-2 h-5 w-5" />
              </button>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Step 4: Reframe the Thought</h3>
            <textarea
              name="reframedThought"
              value={currentExercise.reframedThought}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              placeholder="Write a more balanced or realistic thought"
              rows="4"
            />
            <div className="flex justify-between">
              <button onClick={() => setStep(3)} className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600 transition duration-300 flex items-center">
                <ChevronLeft className="mr-2 h-5 w-5" /> Back
              </button>
              <button onClick={saveExercise} className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition duration-300 flex items-center">
                Save Exercise
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="container mx-auto p-4 max-w-2xl">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-800">Cognitive Restructuring Exercise</h2>
        <button
          onClick={() => setShowTutorial(true)}
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300 flex items-center"
        >
          <Info className="mr-2 h-5 w-5" /> How to Use
        </button>
      </div>

      {showTutorial && <CognitiveRestructuringTutorial onClose={() => setShowTutorial(false)} />}

      <div className="bg-white shadow-md rounded-lg p-6 mb-8">
        <ProgressBar currentStep={step} totalSteps={totalSteps} />
        {renderStep()}
      </div>

      <div className="mt-8 space-y-4">
        <h3 className="text-xl font-bold text-gray-800">Previous Exercises</h3>
        {exercises.map(exercise => (
          <div key={exercise.id} className="bg-white shadow-md rounded-lg p-4 space-y-2">
            <p><strong className="text-gray-700">Negative Thought:</strong> {exercise.negativeThought}</p>
            <p><strong className="text-gray-700">Cognitive Distortions:</strong> {exercise.cognitiveDistortions.join(', ')}</p>
            <p><strong className="text-gray-700">Challenging Questions:</strong> {exercise.challengingQuestions}</p>
            <p><strong className="text-gray-700">Reframed Thought:</strong> {exercise.reframedThought}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CognitiveRestructuringExercise;