import React, { useState, useEffect } from 'react';
import { AlertTriangle, CheckCircle, MessageCircle, TrendingUp, Calendar, Book } from 'lucide-react';

const AlertCard = ({ alert, onRespond }) => (
  <div className="bg-red-100 border-l-4 border-red-500 p-4 mb-4 rounded-r-lg">
    <div className="flex items-center">
      <AlertTriangle className="text-red-500 mr-2" />
      <h3 className="text-lg font-semibold text-red-700">{alert.type}</h3>
    </div>
    <p className="mt-2 text-red-700">{alert.message}</p>
    <div className="mt-4 flex justify-end">
      <button
        onClick={() => onRespond(alert.id)}
        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300"
      >
        Respond
      </button>
    </div>
  </div>
);

const MoodChart = ({ moodData }) => (
  <div className="bg-white p-4 rounded-lg shadow mb-4">
    <h3 className="text-lg font-semibold mb-2 flex items-center">
      <TrendingUp className="mr-2" /> Mood Trend
    </h3>
    {/* Implement a chart here using a library like recharts */}
    <p>Mood chart visualization would go here</p>
  </div>
);

const JournalEntry = ({ entry }) => (
  <div className="bg-white p-4 rounded-lg shadow mb-4">
    <h3 className="text-lg font-semibold mb-2 flex items-center">
      <Book className="mr-2" /> Journal Entry
    </h3>
    <p className="text-sm text-gray-500">{new Date(entry.date).toLocaleDateString()}</p>
    <p className="mt-2">{entry.content}</p>
  </div>
);

const UpcomingActivity = ({ activity }) => (
  <div className="bg-white p-4 rounded-lg shadow mb-4">
    <h3 className="text-lg font-semibold mb-2 flex items-center">
      <Calendar className="mr-2" /> Upcoming Activity
    </h3>
    <p className="text-sm text-gray-500">{new Date(activity.date).toLocaleString()}</p>
    <p className="mt-2">{activity.description}</p>
  </div>
);

const SupportNetworkDashboard = () => {
  const [userData, setUserData] = useState(null);
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    // TODO: Fetch user data and alerts from backend
    // This is mock data for demonstration
    setUserData({
      name: "John Doe",
      moodData: [/* mood data points */],
      journalEntries: [
        { id: 1, date: "2024-07-20T10:00:00Z", content: "I'm feeling a bit overwhelmed today, but I'm trying to stay positive." }
      ],
      upcomingActivities: [
        { id: 1, date: "2024-07-21T15:00:00Z", description: "Therapy appointment" }
      ]
    });
    setAlerts([
      { id: 1, type: "Crisis Alert", message: "John has reported feeling severely depressed. Please check in as soon as possible." }
    ]);
  }, []);

  const handleRespondToAlert = (alertId) => {
    // TODO: Implement response logic
    console.log(`Responding to alert ${alertId}`);
    // This could open a chat interface or provide contact information
  };

  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-4xl mx-auto mt-10 p-6 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold text-gray-800 mb-6">Support Dashboard for {userData.name}</h2>

      {alerts.length > 0 && (
        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-4">Active Alerts</h3>
          {alerts.map(alert => (
            <AlertCard key={alert.id} alert={alert} onRespond={handleRespondToAlert} />
          ))}
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <MoodChart moodData={userData.moodData} />
        </div>
        <div>
          {userData.journalEntries.map(entry => (
            <JournalEntry key={entry.id} entry={entry} />
          ))}
        </div>
      </div>

      <div className="mt-6">
        <h3 className="text-xl font-semibold mb-4">Upcoming Activities</h3>
        {userData.upcomingActivities.map(activity => (
          <UpcomingActivity key={activity.id} activity={activity} />
        ))}
      </div>

      <div className="mt-6 flex justify-center">
        <button className="bg-green-500 text-white px-6 py-3 rounded-lg flex items-center hover:bg-green-600 transition duration-300">
          <MessageCircle className="mr-2" />
          Send a Message
        </button>
      </div>
    </div>
  );
};

export default SupportNetworkDashboard;