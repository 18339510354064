import React, { useState, useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Area } from 'recharts';

const MoodTrends = ({ entries }) => {
  const [timeRange, setTimeRange] = useState('daily');

  const parseDate = (dateString) => {
    const [datePart, timePart] = dateString.split(', ');
    const [year, month, day] = datePart.split('-');
    const [time, period] = timePart.split(' ');
    let [hours, minutes, seconds] = time.split(':');
    
    if (period.toLowerCase() === 'p.m.' && hours !== '12') {
      hours = parseInt(hours) + 12;
    }
    if (period.toLowerCase() === 'a.m.' && hours === '12') {
      hours = '00';
    }
    
    return new Date(year, month - 1, day, hours, minutes, seconds);
  };

  const aggregateData = useMemo(() => {
    if (entries.length === 0) return [];

    const mostRecentDate = new Date(Math.max(...entries.map(e => parseDate(e.date))));

    let startDate;
    switch (timeRange) {
      case 'daily':
        startDate = new Date(mostRecentDate.getTime());
        startDate.setDate(startDate.getDate() - 7);
        break;
      case 'weekly':
        startDate = new Date(mostRecentDate.getTime());
        startDate.setDate(startDate.getDate() - 28);
        break;
      case 'monthly':
        startDate = new Date(mostRecentDate.getTime());
        startDate.setMonth(startDate.getMonth() - 3);
        break;
      default:
        startDate = new Date(0);
    }

    const filteredEntries = entries.filter(entry => {
      const entryDate = parseDate(entry.date);
      return entryDate >= startDate && entryDate <= mostRecentDate;
    });

    const aggregated = filteredEntries.reduce((acc, entry) => {
      const date = parseDate(entry.date);
      let key;
      switch (timeRange) {
        case 'daily':
          key = date.toISOString().split('T')[0];
          break;
        case 'weekly':
          const weekNumber = Math.floor((date.getDate() - 1) / 7) + 1;
          key = `Week ${weekNumber}, ${date.toLocaleString('default', { month: 'short' })}`;
          break;
        case 'monthly':
          key = date.toLocaleString('default', { month: 'short', year: 'numeric' });
          break;
        default:
          key = date.toISOString().split('T')[0];
      }
      if (!acc[key]) {
        acc[key] = { date: key, avgMood: 0, count: 0, minMood: 10, maxMood: 0 };
      }
      acc[key].avgMood += entry.mood;
      acc[key].count += 1;
      acc[key].minMood = Math.min(acc[key].minMood, entry.mood);
      acc[key].maxMood = Math.max(acc[key].maxMood, entry.mood);
      return acc;
    }, {});

    return Object.values(aggregated).map(item => ({
      date: item.date,
      avgMood: Number((item.avgMood / item.count).toFixed(2)),
      minMood: item.minMood,
      maxMood: item.maxMood
    })).sort((a, b) => new Date(a.date) - new Date(b.date));
  }, [entries, timeRange]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-4 border rounded shadow">
          <p className="font-semibold">{label}</p>
          <p className="text-sm">Average Mood: {payload[0].value.toFixed(2)}</p>
          <p className="text-sm">Range: {payload[0].payload.minMood} - {payload[0].payload.maxMood}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 mt-8">
      <h3 className="text-2xl font-bold mb-6 text-gray-800">Mood Trends</h3>
      <div className="mb-6">
        <label className="mr-2 font-medium text-gray-700">Time Range:</label>
        <select
          value={timeRange}
          onChange={(e) => setTimeRange(e.target.value)}
          className="p-2 border rounded shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
        >
          <option value="daily">Daily</option>
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
        </select>
      </div>
      {aggregateData.length === 0 ? (
        <div className="text-center text-gray-600 py-8">No data available for the selected time range.</div>
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={aggregateData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
            <XAxis 
              dataKey="date" 
              angle={-45} 
              textAnchor="end" 
              height={70} 
              interval={0} 
              tick={{fontSize: 12}}
            />
            <YAxis 
              domain={[0, 10]} 
              ticks={[0, 2, 4, 6, 8, 10]} 
              tick={{fontSize: 12}}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Area type="monotone" dataKey="minMood" fill="#8884d8" stroke="#8884d8" fillOpacity={0.1} />
            <Area type="monotone" dataKey="maxMood" fill="#8884d8" stroke="#8884d8" fillOpacity={0.1} />
            <Line 
              type="monotone" 
              dataKey="avgMood" 
              stroke="#8884d8" 
              strokeWidth={3}
              dot={{ r: 4, fill: "#8884d8" }}
              activeDot={{ r: 8 }}
              name="Average Mood" 
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default MoodTrends;