import React, {useState, useEffect}from 'react';

// Custom Slider Component
export const Slider = ({ min = 0, max = 10, step = 1, defaultValue, onValueChange }) => {
    const [value, setValue] = useState(defaultValue[0]);
    const percentage = ((value - min) / (max - min)) * 100;
  
    useEffect(() => {
      setValue(defaultValue[0]);
    }, [defaultValue]);
  
    const handleChange = (event) => {
      const newValue = parseInt(event.target.value);
      setValue(newValue);
      onValueChange([newValue]);
    };
  
    return (
      <div className="relative">
        <input
          type="range"
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={handleChange}
          className="slider-input"
          style={{
            background: `linear-gradient(to right, #3b82f6 0%, #3b82f6 ${percentage}%, #e5e7eb ${percentage}%, #e5e7eb 100%)`
          }}
        />
      </div>
    );
  };

// Custom Button Component
export const Button = ({ onClick, className, children }) => {
  return (
    <button
      onClick={onClick}
      className={`px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ${className}`}
    >
      {children}
    </button>
  );
};