import React from 'react';

const ThoughtRecordTutorial = ({ onClose }) => {
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative top-20 mx-auto p-5 border w-11/12 md:w-3/4 lg:w-1/2 shadow-lg rounded-md bg-white">
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">How to Use the Thought Record Tool</h3>
          <div className="mt-2 px-7 py-3 text-left">
            <ol className="list-decimal pl-5 space-y-3">
              <li><strong>Situation:</strong> Briefly describe the situation that led to the negative emotion. Be specific about what happened, when, and where.</li>
              <li><strong>Automatic Thoughts:</strong> Write down the thoughts that went through your mind in that situation. These are often quick, automatic thoughts that you might not usually notice.</li>
              <li><strong>Emotions:</strong> Identify and rate the intensity of the emotions you felt (e.g., anxiety 7/10, sadness 5/10).</li>
              <li><strong>Evidence For:</strong> List any facts or evidence that support your automatic thought. Be objective and stick to the facts.</li>
              <li><strong>Evidence Against:</strong> List any facts or evidence that do not support your automatic thought. Challenge your thinking here.</li>
              <li><strong>Balanced Thought:</strong> Based on the evidence for and against, create a more balanced, realistic thought about the situation.</li>
              <li><strong>Outcome Emotion:</strong> Reflect on how you feel after developing a more balanced thought. Rate the intensity of your emotions again.</li>
            </ol>
            <p className="mt-4">Remember, the goal is not to always think positively, but to think more realistically and balanced. Regular practice can help you become more aware of your thought patterns and change them over time.</p>
          </div>
          <div className="items-center px-4 py-3">
            <button
              id="ok-btn"
              className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThoughtRecordTutorial;