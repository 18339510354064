import React, { useState, useEffect } from 'react';
import { FaComments, FaUser, FaClock, FaReply, FaPlus, FaArrowLeft } from 'react-icons/fa';

// Mock data for forum categories and threads (keep as is)
const initialCategories = [
  { id: 1, name: 'General Discussion', description: 'Discuss general topics related to mental health' },
  { id: 2, name: 'Coping Strategies', description: 'Share and learn about effective coping strategies' },
  { id: 3, name: 'Success Stories', description: 'Celebrate and share your mental health victories' },
];

const initialThreads = [
  { id: 1, categoryId: 1, title: 'Welcome to our community!', author: 'Admin', date: '2023-07-15', replies: 5 },
  { id: 2, categoryId: 2, title: 'Breathing exercises that help with anxiety', author: 'AnxietyWarrior', date: '2023-07-14', replies: 12 },
  { id: 3, categoryId: 3, title: 'Ive been depression-free for a year!', author: 'HopefulSoul', date: '2023-07-13', replies: 20 },
];

const Forum = () => {
  const [categories, setCategories] = useState(initialCategories);
  const [threads, setThreads] = useState(initialThreads);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [newThreadTitle, setNewThreadTitle] = useState('');
  const [showNewThreadForm, setShowNewThreadForm] = useState(false);

  useEffect(() => {
    // In a real app, you'd fetch categories and threads from an API here
  }, []);

  const handleCreateThread = () => {
    if (newThreadTitle.trim() === '') return;
    const newThread = {
      id: threads.length + 1,
      categoryId: selectedCategory,
      title: newThreadTitle,
      author: 'CurrentUser', // In a real app, this would be the logged-in user
      date: new Date().toISOString().split('T')[0],
      replies: 0,
    };
    setThreads([newThread, ...threads]);
    setNewThreadTitle('');
    setShowNewThreadForm(false);
  };

  return (
    <div className="container mx-auto p-4 max-w-4xl">
      <h1 className="text-3xl font-bold mb-8 text-center text-gray-800">Community Forum</h1>
      
      {!selectedCategory ? (
        <div className="space-y-6">
          <h2 className="text-2xl font-semibold mb-4 text-gray-700">Categories</h2>
          {categories.map(category => (
            <div 
              key={category.id}
              className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition duration-300 cursor-pointer"
              onClick={() => setSelectedCategory(category.id)}
            >
              <h3 className="text-xl font-semibold mb-2 text-blue-600">{category.name}</h3>
              <p className="text-gray-600">{category.description}</p>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <button
            onClick={() => setSelectedCategory(null)}
            className="mb-6 text-blue-500 hover:text-blue-700 transition duration-300 flex items-center"
          >
            <FaArrowLeft className="mr-2" /> Back to Categories
          </button>
          <h2 className="text-2xl font-semibold mb-6 text-gray-700">
            {categories.find(c => c.id === selectedCategory).name}
          </h2>
          
          {!showNewThreadForm ? (
            <button
              onClick={() => setShowNewThreadForm(true)}
              className="mb-6 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition duration-300 flex items-center"
            >
              <FaPlus className="mr-2" /> Create New Thread
            </button>
          ) : (
            <div className="mb-6 bg-white rounded-lg shadow-md p-6">
              <h3 className="text-xl font-semibold mb-4 text-gray-700">Create New Thread</h3>
              <input
                type="text"
                value={newThreadTitle}
                onChange={(e) => setNewThreadTitle(e.target.value)}
                placeholder="Thread title"
                className="w-full p-3 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent mb-4"
              />
              <div className="flex justify-end space-x-2">
                <button
                  onClick={() => setShowNewThreadForm(false)}
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition duration-300"
                >
                  Cancel
                </button>
                <button
                  onClick={handleCreateThread}
                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300"
                >
                  Create Thread
                </button>
              </div>
            </div>
          )}
          
          <div className="space-y-4">
            {threads
              .filter(thread => thread.categoryId === selectedCategory)
              .map(thread => (
                <div key={thread.id} className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition duration-300">
                  <h4 className="text-lg font-semibold mb-2 text-blue-600">{thread.title}</h4>
                  <div className="flex items-center text-sm text-gray-500 space-x-4">
                    <span className="flex items-center">
                      <FaUser className="mr-1" /> {thread.author}
                    </span>
                    <span className="flex items-center">
                      <FaClock className="mr-1" /> {thread.date}
                    </span>
                    <span className="flex items-center">
                      <FaReply className="mr-1" /> {thread.replies} replies
                    </span>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      )}
    </div>
  );
};

export default Forum;