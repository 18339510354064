import React, { useState, useEffect } from 'react';

// Mock data for chat messages
const initialMessages = [
  { id: 1, author: 'SupportBot', content: 'Welcome to the community chat! How can we support you today?', timestamp: '2023-07-15T10:00:00Z' },
  { id: 2, author: 'AnxiousUser', content: 'Im feeling really overwhelmed today. Any tips?', timestamp: '2023-07-15T10:05:00Z' },
  { id: 3, author: 'KindHelper', content: 'Im sorry youre feeling overwhelmed. Have you tried any breathing exercises?', timestamp: '2023-07-15T10:07:00Z' },
];

const Chat = () => {
  const [messages, setMessages] = useState(initialMessages);
  const [newMessage, setNewMessage] = useState('');

  useEffect(() => {
    // In a real app, you'd set up a WebSocket connection here
  }, []);

  const handleSendMessage = () => {
    if (newMessage.trim() === '') return;

    const message = {
      id: messages.length + 1,
      author: 'CurrentUser', // In a real app, this would be the logged-in user
      content: newMessage,
      timestamp: new Date().toISOString(),
    };

    setMessages([...messages, message]);
    setNewMessage('');
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Community Chat</h2>
      <div className="bg-white shadow-md rounded p-4">
        <div className="h-96 overflow-y-auto mb-4">
          {messages.map(message => (
            <div key={message.id} className="mb-2">
              <span className="font-semibold">{message.author}: </span>
              <span>{message.content}</span>
              <span className="text-xs text-gray-500 ml-2">
                {new Date(message.timestamp).toLocaleTimeString()}
              </span>
            </div>
          ))}
        </div>
        <div className="flex">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type your message..."
            className="flex-grow p-2 border rounded-l"
          />
          <button
            onClick={handleSendMessage}
            className="px-4 py-2 bg-blue-500 text-white rounded-r hover:bg-blue-600"
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default Chat;