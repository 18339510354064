import React, { useState } from 'react';
import { Phone, Globe, AlertTriangle } from 'lucide-react';

const emergencyContacts = [
  { country: 'Canada', number: '911', crisisLine: '1-800-668-6868'},
  { country: 'United States', number: '911', crisisLine: '1-800-273-8255' },
  { country: 'United Kingdom', number: '999', crisisLine: '116 123' },
  { country: 'Australia', number: '000', crisisLine: '13 11 14' },
];

const crisisResources = [
  { name: 'National Suicide Prevention Lifeline', url: 'https://suicidepreventionlifeline.org/' },
  { name: 'Crisis Text Line', url: 'https://www.crisistextline.org/' },
  { name: '7 Cups - Online Therapy & Free Counseling', url: 'https://www.7cups.com/' },
];

const commonEmergencies = [
  {
    situation: 'Suicidal thoughts',
    response: 'If you or someone you know is having thoughts of suicide, call a crisis hotline immediately. Do not leave the person alone, and remove any objects that could be used for self-harm.'
  },
  {
    situation: 'Panic attack',
    response: 'Focus on breathing slowly and deeply. Remind yourself that panic attacks are temporary and not life-threatening. If possible, move to a quiet place and practice grounding techniques.'
  },
];

const CrisisSupport = () => {
  const [selectedCountry, setSelectedCountry] = useState('United States');

  return (
    <div className="container mx-auto p-4 space-y-6">
      <h2 className="text-3xl font-bold text-red-600">Crisis Support</h2>
      
      <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
        <div className="flex">
          <AlertTriangle className="h-5 w-5 mr-2" />
          <div>
            <p className="font-bold">Emergency</p>
            <p>If you are in immediate danger or need urgent medical help, please call your local emergency services.</p>
          </div>
        </div>
      </div>

      <div className="bg-white shadow-md rounded-lg p-6">
        <h3 className="text-xl font-semibold mb-4 flex items-center">
          <Phone className="mr-2" /> Emergency Contacts
        </h3>
        <select
          value={selectedCountry}
          onChange={(e) => setSelectedCountry(e.target.value)}
          className="w-full p-2 border rounded mb-4"
        >
          {emergencyContacts.map(contact => (
            <option key={contact.country} value={contact.country}>
              {contact.country}
            </option>
          ))}
        </select>
        {emergencyContacts.find(contact => contact.country === selectedCountry) && (
          <div className="space-y-2">
            <p><strong>Emergency Number:</strong> {emergencyContacts.find(contact => contact.country === selectedCountry).number}</p>
            <p><strong>Crisis Hotline:</strong> {emergencyContacts.find(contact => contact.country === selectedCountry).crisisLine}</p>
          </div>
        )}
      </div>

      <div className="bg-white shadow-md rounded-lg p-6">
        <h3 className="text-xl font-semibold mb-4 flex items-center">
          <Globe className="mr-2" /> Online Crisis Resources
        </h3>
        <ul className="list-disc pl-5 space-y-2">
          {crisisResources.map(resource => (
            <li key={resource.name}>
              <a href={resource.url} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                {resource.name}
              </a>
            </li>
          ))}
        </ul>
      </div>

      <div className="bg-white shadow-md rounded-lg p-6">
        <h3 className="text-xl font-semibold mb-4 flex items-center">
          <AlertTriangle className="mr-2" /> Common Mental Health Emergencies
        </h3>
        <div className="space-y-4">
          {commonEmergencies.map(emergency => (
            <div key={emergency.situation}>
              <h4 className="font-semibold">{emergency.situation}</h4>
              <p>{emergency.response}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CrisisSupport;