import React, { useState, useEffect } from 'react';
import { PlusCircle, Edit, Trash2, AlertTriangle } from 'lucide-react';

const AlertRule = ({ rule, onEdit, onDelete }) => (
  <div className="bg-white p-4 rounded-lg shadow mb-4">
    <div className="flex justify-between items-start">
      <div>
        <h3 className="text-lg font-semibold">{rule.name}</h3>
        <p className="text-sm text-gray-600">{rule.description}</p>
      </div>
      <div className="space-x-2">
        <button onClick={() => onEdit(rule)} className="text-blue-600 hover:text-blue-800">
          <Edit size={18} />
        </button>
        <button onClick={() => onDelete(rule.id)} className="text-red-600 hover:text-red-800">
          <Trash2 size={18} />
        </button>
      </div>
    </div>
    <div className="mt-2">
      <p className="text-sm"><strong>Trigger:</strong> {rule.trigger}</p>
      <p className="text-sm"><strong>Frequency:</strong> {rule.frequency}</p>
      <p className="text-sm"><strong>Message:</strong> {rule.message}</p>
    </div>
  </div>
);

const CustomizableAlertsManager = () => {
  const [alertRules, setAlertRules] = useState([]);
  const [isAddingRule, setIsAddingRule] = useState(false);
  const [editingRule, setEditingRule] = useState(null);
  const [newRule, setNewRule] = useState({
    name: '',
    description: '',
    trigger: '',
    frequency: 'immediately',
    message: ''
  });

  useEffect(() => {
    // TODO: Fetch alert rules from backend
    // For now, we'll use mock data
    setAlertRules([
      {
        id: 1,
        name: 'Low Mood Alert',
        description: 'Alert when mood is consistently low',
        trigger: 'Mood score below 4 for 3 consecutive days',
        frequency: 'immediately',
        message: "I've been feeling down lately. Could use some support."
      },
      {
        id: 2,
        name: 'Journal Keyword Alert',
        description: 'Alert when specific keywords are used in journal',
        trigger: 'Keywords: "overwhelmed", "hopeless", "suicide"',
        frequency: 'immediately',
        message: "I've been having some difficult thoughts. Please check in on me."
      }
    ]);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewRule(prev => ({ ...prev, [name]: value }));
  };

  const handleAddRule = () => {
    // TODO: Implement API call to add rule
    const ruleToAdd = {
      ...newRule,
      id: Date.now() // Temporary ID, should be replaced with server-generated ID
    };
    setAlertRules(prev => [...prev, ruleToAdd]);
    setNewRule({
      name: '',
      description: '',
      trigger: '',
      frequency: 'immediately',
      message: ''
    });
    setIsAddingRule(false);
  };

  const handleEditRule = (rule) => {
    setEditingRule(rule);
    setNewRule(rule);
    setIsAddingRule(true);
  };

  const handleUpdateRule = () => {
    // TODO: Implement API call to update rule
    setAlertRules(prev => prev.map(r => r.id === editingRule.id ? newRule : r));
    setNewRule({
      name: '',
      description: '',
      trigger: '',
      frequency: 'immediately',
      message: ''
    });
    setIsAddingRule(false);
    setEditingRule(null);
  };

  const handleDeleteRule = (id) => {
    // TODO: Implement API call to delete rule
    setAlertRules(prev => prev.filter(r => r.id !== id));
  };

  return (
    <div className="max-w-2xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold text-gray-800 mb-6">Customizable Alerts</h2>
      
      {alertRules.map(rule => (
        <AlertRule
          key={rule.id}
          rule={rule}
          onEdit={handleEditRule}
          onDelete={handleDeleteRule}
        />
      ))}

      {!isAddingRule && (
        <button
          onClick={() => setIsAddingRule(true)}
          className="mt-4 flex items-center text-blue-600 hover:text-blue-800"
        >
          <PlusCircle size={20} className="mr-2" />
          Add New Alert Rule
        </button>
      )}

      {isAddingRule && (
        <div className="mt-6 border-t pt-6">
          <h3 className="text-lg font-semibold mb-4">
            {editingRule ? 'Edit Alert Rule' : 'Add New Alert Rule'}
          </h3>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Name</label>
              <input
                type="text"
                name="name"
                value={newRule.name}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Description</label>
              <input
                type="text"
                name="description"
                value={newRule.description}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Trigger</label>
              <textarea
                name="trigger"
                value={newRule.trigger}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                rows="3"
              ></textarea>
              <p className="mt-1 text-sm text-gray-500">
                Describe the condition that will trigger this alert (e.g., "Mood score below 4 for 3 consecutive days")
              </p>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Frequency</label>
              <select
                name="frequency"
                value={newRule.frequency}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              >
                <option value="immediately">Immediately</option>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
              </select>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Alert Message</label>
              <textarea
                name="message"
                value={newRule.message}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                rows="3"
              ></textarea>
              <p className="mt-1 text-sm text-gray-500">
                This message will be sent to your support network when the alert is triggered
              </p>
            </div>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => {
                  setIsAddingRule(false);
                  setEditingRule(null);
                  setNewRule({
                    name: '',
                    description: '',
                    trigger: '',
                    frequency: 'immediately',
                    message: ''
                  });
                }}
                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Cancel
              </button>
              <button
                onClick={editingRule ? handleUpdateRule : handleAddRule}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                {editingRule ? 'Update Alert Rule' : 'Add Alert Rule'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomizableAlertsManager;