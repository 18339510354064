import React, { useState } from 'react';
import { Slider, Button } from './CustomUIComponents';
import MoodTrends from './MoodTrends';

const moodColors = [
  'bg-red-500',    // 0-1: Very bad mood
  'bg-orange-500', // 2-3: Bad mood
  'bg-yellow-500', // 4-5: Neutral mood
  'bg-lime-500',   // 6-7: Good mood
  'bg-green-500'   // 8-10: Very good mood
];

const moodEmojis = ['😭', '😢', '😐', '🙂', '😄'];

const getMoodColor = (mood) => {
  const index = Math.min(Math.floor(mood / 2), 4);
  return moodColors[index];
};

const getMoodEmoji = (mood) => {
  const index = Math.min(Math.floor(mood / 2), 4);
  return moodEmojis[index];
};

const emotions = [
  'Happy', 'Excited', 'Calm', 'Content', 'Neutral',
  'Anxious', 'Frustrated', 'Sad', 'Angry', 'Overwhelmed'
];

const MoodTracker = () => {
  const [mood, setMood] = useState(5);
  const [emotion, setEmotion] = useState('');
  const [customEmotion, setCustomEmotion] = useState('');
  const [context, setContext] = useState('');
  const [entries, setEntries] = useState([]);
  const [editingId, setEditingId] = useState(null);

  const handleMoodChange = (value) => {
    setMood(value[0]);
  };

  const handleEmotionChange = (e) => {
    setEmotion(e.target.value);
    if (e.target.value !== 'custom') {
      setCustomEmotion('');
    }
  };

  const handleSubmit = () => {
    const currentEmotion = emotion === 'custom' ? customEmotion : emotion;
    if (editingId) {
      setEntries(entries.map(entry => 
        entry.id === editingId ? { ...entry, mood, emotion: currentEmotion, context } : entry
      ));
      setEditingId(null);
    } else {
      const newEntry = {
        id: Date.now(),
        date: new Date().toLocaleString(),
        mood,
        emotion: currentEmotion,
        context
      };
      setEntries([newEntry, ...entries]);
    }
    resetForm();
  };

  const resetForm = () => {
    setMood(5);
    setEmotion('');
    setContext('');
    setCustomEmotion('');
  };

  const handleEdit = (entry) => {
    setMood(entry.mood);
    setEmotion(entry.emotion);
    setContext(entry.context);
    setEditingId(entry.id);
  };

  const handleDelete = (id) => {
    setEntries(entries.filter(entry => entry.id !== id));
  };

  return (
    <div className="container mx-auto p-4 max-w-4xl">
      <h1 className="text-3xl font-bold text-center mb-8 text-gray-800">Mood Tracker</h1>
      <div className="grid md:grid-cols-2 gap-8">
        <div className="bg-white rounded-lg shadow-lg p-6">
          <h2 className="text-xl font-semibold mb-4 text-gray-700">
            {editingId ? 'Edit Your Mood' : 'How are you feeling?'}
          </h2>
          <div className="flex items-center justify-center mb-6">
            <div className={`w-20 h-20 rounded-full ${getMoodColor(mood)} flex items-center justify-center text-4xl transition-all duration-300 ease-in-out transform hover:scale-110`}>
              {getMoodEmoji(mood)}
            </div>
          </div>
          <div className="mb-4">
            <Slider
              min={0}
              max={10}
              step={1}
              defaultValue={[mood]}
              onValueChange={handleMoodChange}
            />
            <p className="text-center mt-2 font-medium">Mood: {mood}/10</p>
          </div>
          <select
            value={emotion}
            onChange={handleEmotionChange}
            className="w-full p-2 border rounded mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Select an emotion</option>
            {emotions.map(e => (
              <option key={e} value={e}>{e}</option>
            ))}
            <option value="custom">Custom</option>
          </select>
          {emotion === 'custom' && (
            <input
              type="text"
              placeholder="Enter your emotion"
              className="w-full p-2 border rounded mb-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={customEmotion}
              onChange={(e) => setCustomEmotion(e.target.value)}
            />
          )}
          <textarea
            placeholder="Any context you want to add?"
            className="w-full p-2 border rounded mb-4 h-24 resize-none focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={context}
            onChange={(e) => setContext(e.target.value)}
          />
          <div className="flex justify-between">
            <Button onClick={handleSubmit} className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded transition duration-300">
              {editingId ? 'Update Mood' : 'Log Mood'}
            </Button>
            {editingId && (
              <Button onClick={() => {setEditingId(null); resetForm();}} className="bg-gray-300 hover:bg-gray-400 text-black px-6 py-2 rounded transition duration-300">
                Cancel
              </Button>
            )}
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-lg p-6">
          <h3 className="text-xl font-semibold mb-4 text-gray-700">Mood Entries</h3>
          <div className="space-y-4 max-h-96 overflow-y-auto pr-2">
            {entries.map(entry => (
              <div key={entry.id} className="border-b pb-3 last:border-b-0">
                <div className="flex items-center">
                  <div className={`w-10 h-10 rounded-full ${getMoodColor(entry.mood)} flex items-center justify-center text-xl mr-3`}>
                    {getMoodEmoji(entry.mood)}
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">{entry.date}</p>
                    <p className="font-medium">Mood: {entry.mood}/10</p>
                    {entry.emotion && <p className="text-sm">Emotion: {entry.emotion}</p>}
                  </div>
                </div>
                {entry.context && <p className="text-sm text-gray-600 mt-2">{entry.context}</p>}
                <div className="mt-2 flex justify-end space-x-2">
                  <button
                    onClick={() => handleEdit(entry)}
                    className="text-blue-500 hover:text-blue-600 text-sm font-medium"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(entry.id)}
                    className="text-red-500 hover:text-red-600 text-sm font-medium"
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-8">
        <MoodTrends entries={entries} />
      </div>
    </div>
  );
};

export default MoodTracker;