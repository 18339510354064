import React, { useState, useEffect } from 'react';
import { Save, PlusCircle, X, Download, Home, BarChart, Book } from 'lucide-react';
import Button from '../components/ui/button';
import Input from './ui/input';
import { BarChart as RechartsBarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import  DateRangePicker  from '../components/ui/date-range-picker';
import { useSwipeable } from 'react-swipeable';
import { useInView } from 'react-intersection-observer';
import JournalPrompts from './JournalPrompts';

const defaultEmotions = [
  "Happy", "Sad", "Angry", "Anxious", "Excited", "Calm", "Frustrated", "Grateful", "Lonely", "Confident"
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8', '#82CA9D', '#FFC0CB', '#A52A2A', '#DDA0DD', '#20B2AA'];

const EmotionTag = ({ emotion, isSelected, onClick, onRemove }) => (
  <Button
    onClick={() => onClick(emotion)}
    className={`m-1 px-3 py-1 rounded-full text-sm flex items-center ${
      isSelected 
        ? 'bg-blue-500 text-white' 
        : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
    }`}
  >
    {emotion}
    {onRemove && (
      <X size={14} className="ml-2 cursor-pointer" onClick={(e) => { e.stopPropagation(); onRemove(emotion); }} />
    )}
  </Button>
);

const JournalEntry = ({ entry, onSave, onNew, emotions, onAddEmotion }) => {
  const [content, setContent] = useState(entry.content);
  const [tags, setTags] = useState(entry.tags || []);
  const [newEmotion, setNewEmotion] = useState('');

  useEffect(() => {
    setContent(entry.content);
    setTags(entry.tags || []);
  }, [entry]);

  const handleSave = () => {
    onSave({ ...entry, content, tags });
  };

  const toggleTag = (emotion) => {
    setTags(prevTags => 
      prevTags.includes(emotion)
        ? prevTags.filter(tag => tag !== emotion)
        : [...prevTags, emotion]
    );
  };

  const handleAddEmotion = () => {
    if (newEmotion && !emotions.includes(newEmotion)) {
      onAddEmotion(newEmotion);
      setNewEmotion('');
    }
  };

  const handleSelectPrompt = (prompt) => {
    setContent(prevContent => `${prevContent}\n\nPrompt: ${prompt}\n\n`);
  };

  return (
    <div className="w-full max-w-2xl mx-auto my-4 bg-yellow-100 shadow-lg border-l-4 sm:border-l-8 border-red-700 rounded-r-lg overflow-hidden sm:transform sm:-rotate-1">
      <div className="bg-white bg-opacity-60 p-4 sm:p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl sm:text-2xl font-handwriting text-gray-800">
            {new Date(entry.timestamp).toLocaleString()}
          </h2>
          <div className="w-6 h-6 sm:w-8 sm:h-8 rounded-full bg-red-700"></div>
        </div>
        <JournalPrompts onSelectPrompt={handleSelectPrompt} />
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">How are you feeling?</label>
          <div className="flex flex-wrap mb-2">
            {emotions.map(emotion => (
              <EmotionTag
                key={emotion}
                emotion={emotion}
                isSelected={tags.includes(emotion)}
                onClick={toggleTag}
              />
            ))}
          </div>
          <div className="flex flex-col sm:flex-row">
            <Input
              type="text"
              value={newEmotion}
              onChange={(e) => setNewEmotion(e.target.value)}
              placeholder="Add custom emotion"
              className="mb-2 sm:mb-0 sm:mr-2"
            />
            <Button onClick={handleAddEmotion} className="w-full sm:w-auto">Add</Button>
          </div>
        </div>
        <textarea
          value={content}
          onChange={(e) => setContent(e.target.value)}
          placeholder="Dear Diary..."
          className="w-full h-48 sm:h-64 p-3 bg-transparent font-handwriting text-base sm:text-lg text-gray-800 border-b border-gray-400 focus:outline-none focus:border-gray-600 resize-none"
          style={{
            backgroundImage: 'repeating-linear-gradient(transparent, transparent 31px, #999 31px, #999 32px, transparent 32px)',
            lineHeight: '32px',
            paddingTop: '8px'
          }}
        />
        <div className="flex flex-col sm:flex-row justify-between mt-4">
          <Button 
            onClick={handleSave}
            className="w-full sm:w-auto mb-2 sm:mb-0 bg-red-700 hover:bg-red-800 text-white font-semibold py-2 px-4 rounded-full transition duration-200 ease-in-out transform hover:scale-105 flex items-center justify-center"
          >
            <Save className="mr-2" size={18} />
            Save Entry
          </Button>
          <Button
            onClick={onNew}
            className="w-full sm:w-auto bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded-full transition duration-200 ease-in-out transform hover:scale-105 flex items-center justify-center"
          >
            <PlusCircle className="mr-2" size={18} />
            New Entry
          </Button>
        </div>
      </div>
    </div>
  );
};

const EntryList = ({ entries, onSelectEntry, filterEmotions, onFilterChange, dateRange, onDateRangeChange, searchTerm, onSearchChange }) => {
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);
  const [visibleEntries, setVisibleEntries] = useState(10);
  const [ref, inView] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      setVisibleEntries((prevVisible) => prevVisible + 10);
    }
  }, [inView]);

  const filteredEntries = entries.filter(entry => 
    (filterEmotions.length === 0 || entry.tags.some(tag => filterEmotions.includes(tag))) &&
    (dateRange.from == null || new Date(entry.timestamp) >= dateRange.from) &&
    (dateRange.to == null || new Date(entry.timestamp) <= dateRange.to) &&
    (searchTerm === '' || entry.content.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <div className="mt-4 sm:mt-8 bg-white bg-opacity-60 p-4 rounded-lg shadow-md">
      <h2 className="text-xl sm:text-2xl font-handwriting text-gray-800 mb-4">Journal Entries</h2>
      <Button 
        onClick={() => setIsFilterExpanded(!isFilterExpanded)}
        className="w-full mb-4 bg-blue-500 text-white"
      >
        {isFilterExpanded ? 'Hide Filters' : 'Show Filters'}
      </Button>
      {isFilterExpanded && (
        <>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Filter by Date Range:</label>
            <DateRangePicker 
              value={dateRange}
              onChange={onDateRangeChange}
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Search Entries:</label>
            <Input
              type="text"
              value={searchTerm}
              onChange={(e) => onSearchChange(e.target.value)}
              placeholder="Search journal entries..."
              className="w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-2">Filter by Emotions:</label>
            <div className="flex flex-wrap">
              {defaultEmotions.map(emotion => (
                <EmotionTag
                  key={emotion}
                  emotion={emotion}
                  isSelected={filterEmotions.includes(emotion)}
                  onClick={() => onFilterChange(emotion)}
                />
              ))}
            </div>
          </div>
        </>
      )}
      <ul className="space-y-2">
        {filteredEntries.slice(0, visibleEntries).map((entry) => (
          <li key={entry.id} className="flex flex-col sm:flex-row justify-between items-start sm:items-center border-b pb-2">
            <div>
              <span className="font-handwriting text-gray-700">
                {new Date(entry.timestamp).toLocaleString()}
              </span>
              <div className="flex flex-wrap mt-1">
                {entry.tags && entry.tags.map(tag => (
                  <span key={tag} className="mr-1 mb-1 px-2 py-1 bg-blue-100 text-blue-800 text-xs rounded-full">
                    {tag}
                  </span>
                ))}
              </div>
            </div>
            <Button
              onClick={() => onSelectEntry(entry)}
              className="mt-2 sm:mt-0 w-full sm:w-auto bg-red-100 text-red-700 hover:bg-red-200 px-3 py-1 rounded-full text-sm"
            >
              View
            </Button>
          </li>
        ))}
      </ul>
      {visibleEntries < filteredEntries.length && (
        <div ref={ref} className="h-10" /> // Invisible element for intersection observer
      )}
    </div>
  );
};

const MoodVisualization = ({ entries, dateRange }) => {
  const filteredEntries = entries.filter(entry => 
    (dateRange.from == null || new Date(entry.timestamp) >= dateRange.from) &&
    (dateRange.to == null || new Date(entry.timestamp) <= dateRange.to)
  );

  const emotionCounts = filteredEntries.reduce((acc, entry) => {
    entry.tags.forEach(tag => {
      acc[tag] = (acc[tag] || 0) + 1;
    });
    return acc;
  }, {});

  const data = Object.entries(emotionCounts).map(([emotion, count]) => ({
    emotion,
    count
  }));

  const dayOfWeekData = filteredEntries.reduce((acc, entry) => {
    const dayOfWeek = new Date(entry.timestamp).toLocaleString('en-US', { weekday: 'long' });
    acc[dayOfWeek] = (acc[dayOfWeek] || 0) + 1;
    return acc;
  }, {});

  const timeOfDayData = filteredEntries.reduce((acc, entry) => {
    const hour = new Date(entry.timestamp).getHours();
    let timeOfDay;
    if (hour < 6) timeOfDay = 'Night';
    else if (hour < 12) timeOfDay = 'Morning';
    else if (hour < 18) timeOfDay = 'Afternoon';
    else timeOfDay = 'Evening';
    acc[timeOfDay] = (acc[timeOfDay] || 0) + 1;
    return acc;
  }, {});

  return (
    <div className="mt-4 sm:mt-8 bg-white bg-opacity-60 p-4 rounded-lg shadow-md">
      <h2 className="text-xl sm:text-2xl font-handwriting text-gray-800 mb-4">Mood Tracking</h2>
      <div className="grid grid-cols-1 gap-4">
        <div>
          <h3 className="text-lg font-semibold mb-2">Emotion Frequency</h3>
          <ResponsiveContainer width="100%" height={300}>
            <RechartsBarChart data={data}>
              <XAxis dataKey="emotion" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="count" fill="#8884d8" />
            </RechartsBarChart>
          </ResponsiveContainer>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2">Entries by Day of Week</h3>
          <ResponsiveContainer width="100%" height={300}>
            <RechartsBarChart data={Object.entries(dayOfWeekData).map(([day, count]) => ({ day, count }))}>
              <XAxis dataKey="day" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="count" fill="#82ca9d" />
            </RechartsBarChart>
          </ResponsiveContainer>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2">Entries by Time of Day</h3>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={Object.entries(timeOfDayData).map(([time, value]) => ({ name: time, value }))}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {Object.entries(timeOfDayData).map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

const BottomNavigation = ({ activeTab, setActiveTab, onExport }) => {
  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 flex justify-around items-center h-16">
      <Button variant="ghost" onClick={() => setActiveTab('home')} className={activeTab === 'home' ? 'text-blue-500' : ''}>
        <Home size={24} />
      </Button>
      <Button variant="ghost" onClick={() => setActiveTab('entries')} className={activeTab === 'entries' ? 'text-blue-500' : ''}>
        <Book size={24} />
      </Button>
      <Button variant="ghost" onClick={() => setActiveTab('stats')} className={activeTab === 'stats' ? 'text-blue-500' : ''}>
        <BarChart size={24} />
      </Button>
      <Button variant="ghost" onClick={onExport} className="text-green-500">
        <Download size={24} />
      </Button>
    </div>
  );
};

const MentalHealthJournal = () => {
  const [entries, setEntries] = useState([]);
  const [currentEntry, setCurrentEntry] = useState(null);
  const [emotions, setEmotions] = useState(defaultEmotions);
  const [filterEmotions, setFilterEmotions] = useState([]);
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState('home');

  useEffect(() => {
    const storedEntries = localStorage.getItem('journalEntries');
    const storedEmotions = localStorage.getItem('customEmotions');
    if (storedEntries) {
      setEntries(JSON.parse(storedEntries));
    }
    if (storedEmotions) {
      setEmotions([...defaultEmotions, ...JSON.parse(storedEmotions)]);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('journalEntries', JSON.stringify(entries));
  }, [entries]);

  useEffect(() => {
    const customEmotions = emotions.filter(e => !defaultEmotions.includes(e));
    localStorage.setItem('customEmotions', JSON.stringify(customEmotions));
  }, [emotions]);

  useEffect(() => {
    if (entries.length === 0) {
      createNewEntry();
    } else {
      setCurrentEntry(entries[entries.length - 1]);
    }
  }, [entries]);

  const saveEntry = (updatedEntry) => {
    setEntries(prevEntries => 
      prevEntries.map(entry => 
        entry.id === updatedEntry.id ? updatedEntry : entry
      )
    );
  };

  const createNewEntry = () => {
    const newEntry = {
      id: Date.now().toString(),
      timestamp: new Date().toISOString(),
      content: '',
      tags: []
    };
    setEntries(prevEntries => [...prevEntries, newEntry]);
    setCurrentEntry(newEntry);
  };

  const selectEntry = (entry) => {
    setCurrentEntry(entry);
    setActiveTab('home');
  };

  const addEmotion = (newEmotion) => {
    setEmotions(prevEmotions => [...prevEmotions, newEmotion]);
  };

  const toggleFilterEmotion = (emotion) => {
    setFilterEmotions(prevFilters => 
      prevFilters.includes(emotion)
        ? prevFilters.filter(e => e !== emotion)
        : [...prevFilters, emotion]
    );
  };

  const exportData = () => {
    const dataStr = JSON.stringify(entries);
    const dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
    const exportFileDefaultName = 'journal_entries.json';

    const linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      const currentIndex = entries.findIndex(e => e.id === currentEntry.id);
      if (currentIndex < entries.length - 1) {
        setCurrentEntry(entries[currentIndex + 1]);
      }
    },
    onSwipedRight: () => {
      const currentIndex = entries.findIndex(e => e.id === currentEntry.id);
      if (currentIndex > 0) {
        setCurrentEntry(entries[currentIndex - 1]);
      }
    },
  });

  return (
    <div className="container mx-auto px-4 py-6 bg-gradient-to-br from-amber-50 to-yellow-100 min-h-screen pb-20">
      <h1 className="text-3xl sm:text-4xl font-bold text-center mb-6 text-red-800 font-handwriting">My Journal</h1>
      {/* <Button onClick={exportData} className="w-full sm:w-auto mb-4 bg-green-500 hover:bg-green-600 text-white">
        <Download className="mr-2" size={18} />
        Export Journal Data
      </Button> */}
      {activeTab === 'home' && currentEntry && (
        <div {...handlers}>
          <JournalEntry
            entry={currentEntry}
            onSave={saveEntry}
            onNew={createNewEntry}
            emotions={emotions}
            onAddEmotion={addEmotion}
          />
        </div>
      )}
      {activeTab === 'entries' && (
        <EntryList 
          entries={entries} 
          onSelectEntry={selectEntry} 
          filterEmotions={filterEmotions}
          onFilterChange={toggleFilterEmotion}
          dateRange={dateRange}
          onDateRangeChange={setDateRange}
          searchTerm={searchTerm}
          onSearchChange={setSearchTerm}
        />
      )}
      {activeTab === 'stats' && (
        <MoodVisualization entries={entries} dateRange={dateRange} />
      )}
      <BottomNavigation activeTab={activeTab} setActiveTab={setActiveTab }  onExport={exportData} />
    </div>
  );
};

export default MentalHealthJournal;