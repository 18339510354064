import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  ClerkProvider,
  SignedIn,
  SignedOut,
  RedirectToSignIn,
  SignIn,
  SignUp,
} from "@clerk/clerk-react";
import Layout from "./components/Layout";
import Home from "./components/Home";
import MoodTracker from "./components/MoodTracker";
import Journaling from "./components/Journaling";
import ThoughtRecordTool from "./components/ThoughtRecordTool";
import CognitiveRestructuringExercise from "./components/CognitiveRestructuringExercise";
import BehavioralActivationPlanner from "./components/BehavioralActivationPlanner";
import GoalHabitTracker from "./components/GoalHabitTracker";
import ResourceLibrary from "./components/ResourceLibrary";
import CrisisSupport from "./components/CrisisSupport";
import Forum from "./components/Forum";
import Chat from "./components/Chat";
import Scrapbook from "./components/ScrapBook";
import UserProfile from "./components/UserProfile";
import SupportNetworkManager from "./components/SupportNetworkManager";
import CustomizableAlertsManager from "./components/CustomizableAlertsManager";
import SupportNetworkDashboard from "./components/SupportNetworkDashboard";
import ClerkUserProfile from "./components/ClerkUserProfile";

// Wrap protected components with this
const ProtectedRoute = ({ children }) => {
  return (
    <>
      <SignedIn>{children}</SignedIn>
      <SignedOut>
        <RedirectToSignIn />
      </SignedOut>
    </>
  );
};

function App() {
  return (
    <ClerkProvider publishableKey="pk_test_c2hpbmluZy1tb29zZS04OC5jbGVyay5hY2NvdW50cy5kZXYk">
      <Router>
        <Routes>
          <Route
            path="/sign-in/*"
            element={<SignIn routing="path" path="/sign-in" />}
          />
          <Route
            path="/sign-up/*"
            element={<SignUp routing="path" path="/sign-up" />}
          />
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route
              path="mood-tracker"
              element={
                <ProtectedRoute>
                  <MoodTracker />
                </ProtectedRoute>
              }
            />
            <Route
              path="journaling"
              element={
                <ProtectedRoute>
                  <Journaling />
                </ProtectedRoute>
              }
            />
            <Route
              path="thought-record"
              element={
                <ProtectedRoute>
                  <ThoughtRecordTool />
                </ProtectedRoute>
              }
            />
            <Route
              path="cognitive-restructuring"
              element={
                <ProtectedRoute>
                  <CognitiveRestructuringExercise />
                </ProtectedRoute>
              }
            />
            <Route
              path="activation-planner"
              element={
                <ProtectedRoute>
                  <BehavioralActivationPlanner />
                </ProtectedRoute>
              }
            />
            <Route
              path="goal-habit-tracker"
              element={
                <ProtectedRoute>
                  <GoalHabitTracker />
                </ProtectedRoute>
              }
            />
            <Route path="resource-library" element={<ResourceLibrary />} />
            <Route path="crisis-support" element={<CrisisSupport />} />
            <Route
              path="forum"
              element={
                <ProtectedRoute>
                  <Forum />
                </ProtectedRoute>
              }
            />
            <Route
              path="chat"
              element={
                <ProtectedRoute>
                  <Chat />
                </ProtectedRoute>
              }
            />
            <Route
              path="scrapbook"
              element={
                <ProtectedRoute>
                  <Scrapbook />
                </ProtectedRoute>
              }
            />
            {/* <Route
              path="profile"
              element={
                <ProtectedRoute>
                  <ClerkUserProfile />
                </ProtectedRoute>
              }
            /> */}
            <Route
              path="support-network"
              element={
                <ProtectedRoute>
                  <SupportNetworkManager />
                </ProtectedRoute>
              }
            />
            <Route
              path="alerts"
              element={
                <ProtectedRoute>
                  <CustomizableAlertsManager />
                </ProtectedRoute>
              }
            />
            <Route
              path="support-dashboard"
              element={
                <ProtectedRoute>
                  <SupportNetworkDashboard />
                </ProtectedRoute>
              }
            />
          </Route>
        </Routes>
      </Router>
    </ClerkProvider>
  );
}

export default App;
