import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

// Define activity categories
const categories = [
  { id: 'social', name: 'Social', color: '#FFA500' },  // Orange
  { id: 'physical', name: 'Physical', color: '#32CD32' },  // Lime Green
  { id: 'creative', name: 'Creative', color: '#FF69B4' },  // Hot Pink
  { id: 'relaxation', name: 'Relaxation', color: '#1E90FF' },  // Dodger Blue
  { id: 'productivity', name: 'Productivity', color: '#9370DB' },  // Medium Purple
  { id: 'other', name: 'Other', color: '#808080' }  // Gray
];

const BehavioralActivationPlanner = () => {
  const [activities, setActivities] = useState([]);
  const [newActivity, setNewActivity] = useState({
    name: '',
    category: '',
    difficulty: 5,
    moodImprovement: 5,
    scheduled: null,
    completed: false,
    moodBefore: null,
    moodAfter: null
  });
  const [view, setView] = useState('list');
  const [filterCategory, setFilterCategory] = useState('');

  useEffect(() => {
    const savedActivities = JSON.parse(localStorage.getItem('activationActivities')) || [];
    setActivities(savedActivities);
  }, []);

  const saveActivities = (updatedActivities) => {
    setActivities(updatedActivities);
    localStorage.setItem('activationActivities', JSON.stringify(updatedActivities));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewActivity(prev => ({ ...prev, [name]: value }));
  };

  const addActivity = () => {
    if (newActivity.name.trim() === '' || newActivity.category === '') return;
    const updatedActivities = [...activities, { ...newActivity, id: Date.now() }];
    saveActivities(updatedActivities);
    setNewActivity({
      name: '',
      category: '',
      difficulty: 5,
      moodImprovement: 5,
      scheduled: null,
      completed: false,
      moodBefore: null,
      moodAfter: null
    });
  };

  const scheduleActivity = (id, date) => {
    const updatedActivities = activities.map(activity => 
      activity.id === id ? { ...activity, scheduled: date } : activity
    );
    saveActivities(updatedActivities);
  };

  const completeActivity = (id, moodBefore, moodAfter) => {
    const updatedActivities = activities.map(activity => 
      activity.id === id ? { ...activity, completed: true, moodBefore, moodAfter } : activity
    );
    saveActivities(updatedActivities);
  };

  const filteredActivities = filterCategory
    ? activities.filter(activity => activity.category === filterCategory)
    : activities;

  const calendarEvents = filteredActivities
    .filter(activity => activity.scheduled)
    .map(activity => ({
      id: activity.id,
      title: activity.name,
      start: new Date(activity.scheduled),
      end: new Date(new Date(activity.scheduled).getTime() + 60 * 60 * 1000),
      allDay: false,
      resource: activity.category
    }));

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Behavioral Activation Planner</h2>
      
      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">Add New Activity</h3>
        <input
          type="text"
          name="name"
          value={newActivity.name}
          onChange={handleInputChange}
          placeholder="Activity name"
          className="w-full p-2 mb-2 border rounded"
        />
        <select
          name="category"
          value={newActivity.category}
          onChange={handleInputChange}
          className="w-full p-2 mb-2 border rounded"
        >
          <option value="">Select a category</option>
          {categories.map(category => (
            <option key={category.id} value={category.id}>{category.name}</option>
          ))}
        </select>
        <div className="flex mb-2">
          <div className="w-1/2 mr-2">
            <label className="block">Difficulty (1-10):</label>
            <input
              type="number"
              name="difficulty"
              value={newActivity.difficulty}
              onChange={handleInputChange}
              min="1"
              max="10"
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="w-1/2 ml-2">
            <label className="block">Mood Improvement (1-10):</label>
            <input
              type="number"
              name="moodImprovement"
              value={newActivity.moodImprovement}
              onChange={handleInputChange}
              min="1"
              max="10"
              className="w-full p-2 border rounded"
            />
          </div>
        </div>
        <button
          onClick={addActivity}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Add Activity
        </button>
      </div>

      <div className="mb-4 flex justify-between items-center">
        <div>
          <button
            onClick={() => setView('list')}
            className={`mr-2 px-4 py-2 rounded ${view === 'list' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            List View
          </button>
          <button
            onClick={() => setView('calendar')}
            className={`px-4 py-2 rounded ${view === 'calendar' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            Calendar View
          </button>
        </div>
        <select
          value={filterCategory}
          onChange={(e) => setFilterCategory(e.target.value)}
          className="p-2 border rounded"
        >
          <option value="">All Categories</option>
          {categories.map(category => (
            <option key={category.id} value={category.id}>{category.name}</option>
          ))}
        </select>
      </div>

      {view === 'list' ? (
        <div>
          <h3 className="text-xl font-semibold mb-2">Your Activities</h3>
          {filteredActivities.map(activity => (
            <div 
              key={activity.id} 
              className="mb-4 p-4 border rounded"
              style={{ borderLeftWidth: '4px', borderLeftColor: categories.find(c => c.id === activity.category)?.color }}
            >
              <h4 className="font-bold">{activity.name}</h4>
              <p>Category: {categories.find(c => c.id === activity.category)?.name}</p>
              <p>Difficulty: {activity.difficulty}/10</p>
              <p>Mood Improvement: {activity.moodImprovement}/10</p>
              {!activity.scheduled ? (
                <div>
                  <label className="block mt-2">Schedule:</label>
                  <input
                    type="datetime-local"
                    onChange={(e) => scheduleActivity(activity.id, e.target.value)}
                    className="w-full p-2 border rounded"
                  />
                </div>
              ) : activity.completed ? (
                <div>
                  <p>Completed on: {new Date(activity.scheduled).toLocaleString()}</p>
                  <p>Mood Before: {activity.moodBefore}/10</p>
                  <p>Mood After: {activity.moodAfter}/10</p>
                </div>
              ) : (
                <div>
                  <p>Scheduled for: {new Date(activity.scheduled).toLocaleString()}</p>
                  <button
                    onClick={() => {
                      const moodBefore = prompt("Rate your mood before the activity (1-10):");
                      const moodAfter = prompt("Rate your mood after the activity (1-10):");
                      completeActivity(activity.id, moodBefore, moodAfter);
                    }}
                    className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                  >
                    Mark as Completed
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div style={{ height: '500px' }}>
          <Calendar
            localizer={localizer}
            events={calendarEvents}
            startAccessor="start"
            endAccessor="end"
            style={{ height: '100%' }}
            eventPropGetter={(event) => ({
              style: {
                backgroundColor: categories.find(c => c.id === event.resource)?.color,
              },
            })}
          />
        </div>
      )}
    </div>
  );
};

export default BehavioralActivationPlanner;