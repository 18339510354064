import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import  Button  from '../components/ui/button';

const prompts = [
  "I feel ___",
  "5 things I am grateful for today.",
  "Self check-in: What do I feel? What do I need? How can I take care of myself in this moment?",
  "How do I want to feel today?", 
  "Who do I want to be today? ",
  "What do I want to receive today? ",
  "What do I want to give today?",
  "What's the most significant thing that happened to me today?",
  "If I could change one thing about today, what would it be and why?",
  "What's one small step I can take towards my goals tomorrow?",
  "Describe a moment of joy or peace you experienced recently.",
  "What's a challenge I'm facing, and how can I approach it differently?",
  "Write a letter to your future self, one year from now."
];

const JournalPrompts = ({ onSelectPrompt }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState(null);

  const handleSelectPrompt = (prompt) => {
    setSelectedPrompt(prompt);
    onSelectPrompt(prompt);
    setIsOpen(false);
  };

  return (
    <div className="mb-4">
      <Button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full justify-between bg-blue-500 hover:bg-blue-600 text-white"
      >
        {selectedPrompt || "Choose a Journal Prompt"}
        {isOpen ? <ChevronUp className="ml-2" /> : <ChevronDown className="ml-2" />}
      </Button>
      {isOpen && (
        <div className="mt-2 p-2 bg-white border border-gray-200 rounded-md shadow-lg">
          {prompts.map((prompt, index) => (
            <div
              key={index}
              className="p-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleSelectPrompt(prompt)}
            >
              {prompt}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default JournalPrompts;