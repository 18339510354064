import React, { useState, Fragment } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { useUser, useClerk, SignedIn, SignedOut, SignInButton, UserButton } from "@clerk/clerk-react";
import ttmeLogo from '../assets/ttmeLogo.png'
import UserProfileSection from './UserProfileSection'
import { 
  XMarkIcon, 
  Bars3Icon,
  HomeIcon,
  ChartBarIcon,
  PencilIcon,
  BeakerIcon,
  CalendarIcon,
  FlagIcon,
  BookOpenIcon,
  PhoneIcon,
  ChatBubbleLeftRightIcon,
} from '@heroicons/react/24/outline';
import {  AlertTriangle,  Users2, LogOut } from 'lucide-react';
import { GrDashboard } from 'react-icons/gr';

const navigation = [
  { name: 'Home', href: '/', icon: HomeIcon },
  { 
    name: 'Self-Help Tools', 
    children: [
      { name: 'Mood Tracker', href: '/mood-tracker', icon: ChartBarIcon },
      { name: 'Journaling', href: '/journaling', icon: PencilIcon },
      { name: 'Thought Record', href: '/thought-record', icon: BeakerIcon },
      { name: 'Cognitive Restructuring', href: '/cognitive-restructuring', icon: BeakerIcon },
      { name: 'ScrapBook', href: '/scrapbook', icon: PencilIcon}
    ]
  },
  { 
    name: 'Planning & Goals', 
    children: [
      { name: 'Activation Planner', href: '/activation-planner', icon: CalendarIcon },
      { name: 'Goal & Habit Tracker', href: '/goal-habit-tracker', icon: FlagIcon },
    ]
  },
  {
    name: 'Support Tools',
    children: [
      { name: 'Support Dashboard', href: '/support-dashboard', icon: GrDashboard},
      { name: 'Support Network', href: '/support-network', icon: Users2},
      { name: 'Alerts Manager', href: '/alerts', icon: AlertTriangle}
    ]
  },
  { name: 'Resource Library', href: '/resource-library', icon: BookOpenIcon },
  { name: 'Crisis Support', href: '/crisis-support', icon: PhoneIcon },
  { name: 'Community', href: '/forum', icon: ChatBubbleLeftRightIcon },
  // { name: 'Profile', href: '/profile', icon: User2},
 
];

const Layout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [expandedItems, setExpandedItems] = useState({});
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useUser()
  const { signOut } = useClerk();

  

  const handleSignOut = async () => {
    await signOut();
    navigate('/')
  }

  const toggleExpanded = (name) => {
    setExpandedItems(prev => ({ ...prev, [name]: !prev[name] }));
  };

  const NavItem = ({ item, depth = 0 }) => {
    const isActive = location.pathname === item.href;
    const hasChildren = item.children && item.children.length > 0;

    return (
      <div>
        {item.href ? (
          <Link
            to={item.href}
            className={`group flex items-center px-2 py-2 text-sm font-medium rounded-md ${
              isActive
                ? ' text-gray-900'
                : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
            }`}
            style={{ paddingLeft: `${depth * 1}rem` }}
            onClick={() => setSidebarOpen(false)}
          >
            {item.icon && <item.icon className="mr-3 h-6 w-6" />}
            {item.name}
          </Link>
        ) : (
          <button
            className="w-full group flex items-center px-2 py-2 text-sm font-medium rounded-md text-gray-600 hover:bg-gray-50 hover:text-gray-900"
            onClick={() => toggleExpanded(item.name)}
            style={{ paddingLeft: `${depth * 1}rem` }}
          >
            {item.icon && <item.icon className="mr-3 h-6 w-6" />}
            {item.name}
            <span className="ml-auto">
              {expandedItems[item.name] ? '▼' : '▶'}
            </span>
          </button>
        )}
        {hasChildren && expandedItems[item.name] && (
          <div className="ml-4">
            {item.children.map((child) => (
              <NavItem key={child.name} item={child} depth={depth + 1} />
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="flex h-screen">
      {/* Mobile sidebar */}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src={ttmeLogo}
                      alt="Your Company"
                    />
                  </div>
                  <nav className="mt-5 space-y-1 px-2">
                    {navigation.map((item) => (
                      <NavItem key={item.name} item={item} />
                    ))}
                  </nav>
                </div>
                <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
                  {/* <a href="#" className="group block flex-shrink-0"> */}
                    <SignedIn>

                    <div className="flex items-center">
                      {/* <div>
                        <UserButton >
                        </UserButton>
                      </div>
                      <div className="ml-3">
                        <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                          {user?.username}
                        </p>
                      </div>
                      <button  
                        onClick={handleSignOut}
                        className='ml-2 p-1 text-gray-400 hover:text-gray-500'>
                          <LogOut classname="h-5 w-5"/>
                        </button> */}
                                      <UserProfileSection handleSignOut={handleSignOut} />

                    </div>
                    </SignedIn>
                    <SignedOut>
            <div className="flex flex-shrink-0">
              <SignInButton className=" text-center px-4 py-2 text-sm font-medium rounded-md text-white bg-indigo-600 " />
            </div>
          </SignedOut>
                  {/* </a> */}
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0">{/* Force sidebar to shrink to fit close icon */}</div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
        <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
          <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
            <div className="flex flex-shrink-0 items-center px-4">
              <img className="h-8 w-auto" src={ttmeLogo} alt="Your Company" />
            </div>
            <nav className="mt-5 flex-1 space-y-1 bg-white px-2">
              {navigation.map((item) => (
                <NavItem key={item.name} item={item} />
              ))}
            </nav>
          </div>
          <SignedIn>
            <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
              {/* <Link to="/profile" className="group block w-full flex-shrink-0">
                <div className="flex items-center">
                  <div>
                    <UserButton />
                  </div>
                  <div className="ml-3 flex-grow">
                    <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                      {user?.username}
                    </p>
                    
                  </div>
                  <button
                    onClick={handleSignOut}
                    // className="ml-2 p-1 text-gray-400 hover:text-gray-500"
                  >
                    <LogOut className="h-5 w-5" />
                  </button>
                </div>
              </Link> */}
              <UserProfileSection handleSignOut={handleSignOut} />
            </div>
          </SignedIn>
          <SignedOut>
            <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
              {/* <Link
                to="/sign-in"
                className="w-full text-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Sign In
              </Link> */}
              <SignInButton className="w-full text-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-custom-teal hover:bg-custom-teal-dark" />
            </div>
          </SignedOut>
        </div>
      </div>

      {/* Mobile top bar */}
      <div className="flex flex-1 flex-col md:pl-64">
        <div className="sticky top-0 z-10  pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        {/* Main content */}
        <main className="flex-1">
          <div className="py-6">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
              <Outlet />
            </div>
          </div>
        </main>

        {/* Footer */}
        <footer className="bg-white shadow mt-8 py-4">
          <div className="container mx-auto px-4">
            <p className="text-center text-gray-500 text-sm">
              © 2023 Talk To Me App. All rights reserved.
            </p>
            <div className="flex justify-center mt-2">
              <Link to="/about" className="text-sm text-gray-500 hover:text-gray-700 mr-4">
                About
              </Link>
              <Link to="/privacy" className="text-sm text-gray-500 hover:text-gray-700 mr-4">
                Privacy Policy
              </Link>
              <Link to="/terms" className="text-sm text-gray-500 hover:text-gray-700">
                Terms of Service
              </Link>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Layout;