import React, { useState, useEffect } from 'react';

const ScrapbookItem = ({ id, content, x, y, onDragStart, onDragEnd }) => {
  return (
    <div
      draggable
      onDragStart={(e) => onDragStart(e, id)}
      onDragEnd={onDragEnd}
      className="scrapbook-item absolute bg-white p-2 rounded shadow cursor-move"
      style={{
        left: `${x}px`,
        top: `${y}px`,
      }}
    >
      {content}
    </div>
  );
};

const Scrapbook = () => {
  const [items, setItems] = useState([
    { id: 'item1', content: '🌟 Star', x: 50, y: 50 },
    { id: 'item2', content: '🎨 Palette', x: 100, y: 100 },
    { id: 'item3', content: '📷 Camera', x: 150, y: 150 },
  ]);

  const [draggedItem, setDraggedItem] = useState(null);

  useEffect(() => {
    console.log('Current items state:', items);
  }, [items]);

  const onDragStart = (e, id) => {
    setDraggedItem(id);
    e.dataTransfer.setData('text/plain', id);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = (e) => {
    e.preventDefault();
    if (draggedItem) {
      const newX = e.clientX - e.target.offsetLeft;
      const newY = e.clientY - e.target.offsetTop;
      
      setItems(items.map(item => 
        item.id === draggedItem ? { ...item, x: newX, y: newY } : item
      ));
      
      setDraggedItem(null);
    }
  };

  return (
    <div className="scrapbook-container w-full min-h-screen bg-gray-100 relative overflow-hidden p-4">
      <h1 className="text-2xl font-bold mb-4">Scrapbook</h1>
      <div
        className="scrapbook-page w-full h-[600px] border-2 border-dashed border-gray-300 relative"
        onDragOver={onDragOver}
        onDrop={onDrop}
      >
        {items.map((item) => (
          <ScrapbookItem
            key={item.id}
            id={item.id}
            content={item.content}
            x={item.x}
            y={item.y}
            onDragStart={onDragStart}
          />
        ))}
      </div>
      <div className="mt-4">
        <h2 className="text-lg font-semibold">Debug Information:</h2>
        <pre className="bg-gray-200 p-2 rounded mt-2">
          {JSON.stringify(items, null, 2)}
        </pre>
      </div>
    </div>
  );
};

export default Scrapbook;