import React from 'react';

const CognitiveRestructuringTutorial = ({ onClose }) => {
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative top-20 mx-auto p-5 border w-11/12 md:w-3/4 lg:w-1/2 shadow-lg rounded-md bg-white">
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">How to Use the Cognitive Restructuring Exercise</h3>
          <div className="mt-2 px-7 py-3 text-left">
            <ol className="list-decimal pl-5 space-y-3">
              <li>
                <strong>Identify the Negative Thought:</strong> Write down a negative thought you've been having. Be specific about what you're thinking and feeling.
              </li>
              <li>
                <strong>Identify Cognitive Distortions:</strong> Review the list of common cognitive distortions and select any that apply to your negative thought. This helps you recognize patterns in your thinking that might be inaccurate or unhelpful.
              </li>
              <li>
                <strong>Challenge the Thought:</strong> Ask yourself questions to challenge the negative thought. For example:
                <ul className="list-disc pl-5 mt-2">
                  <li>What evidence supports this thought? What evidence contradicts it?</li>
                  <li>Are you confusing a thought with a fact?</li>
                  <li>Are you focusing on just one part of the situation and ignoring the rest?</li>
                  <li>How might someone else view this situation?</li>
                </ul>
              </li>
              <li>
                <strong>Reframe the Thought:</strong> Based on your answers to the challenging questions, try to come up with a more balanced or realistic thought. This doesn't mean forcing positivity, but rather finding a middle ground that acknowledges both positive and negative aspects of the situation.
              </li>
            </ol>
            <p className="mt-4">Remember, cognitive restructuring is a skill that improves with practice. Don't be discouraged if it feels difficult at first. Regular practice can help you become more aware of your thought patterns and change them over time.</p>
          </div>
          <div className="items-center px-4 py-3">
            <button
              id="ok-btn"
              className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CognitiveRestructuringTutorial;