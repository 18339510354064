import React, { useState } from 'react';

const ResourceSubmission = ({ onSubmit }) => {
  const [newResource, setNewResource] = useState({
    title: '',
    description: '',
    type: 'article',
    category: '',
    content: '',
    url: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewResource(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ ...newResource, id: Date.now(), rating: 0, ratingCount: 0 });
    setNewResource({
      title: '',
      description: '',
      type: 'article',
      category: '',
      content: '',
      url: '',
    });
  };

  return (
    <form onSubmit={handleSubmit} className="mb-8 p-4 border rounded">
      <h3 className="text-xl font-semibold mb-4">Submit a New Resource</h3>
      <input
        type="text"
        name="title"
        value={newResource.title}
        onChange={handleInputChange}
        placeholder="Resource Title"
        className="w-full p-2 mb-2 border rounded"
        required
      />
      <textarea
        name="description"
        value={newResource.description}
        onChange={handleInputChange}
        placeholder="Resource Description"
        className="w-full p-2 mb-2 border rounded"
        required
      />
      <select
        name="type"
        value={newResource.type}
        onChange={handleInputChange}
        className="w-full p-2 mb-2 border rounded"
        required
      >
        <option value="article">Article</option>
        <option value="video">Video</option>
        <option value="infographic">Infographic</option>
        <option value="audio">Audio</option>
      </select>
      <input
        type="text"
        name="category"
        value={newResource.category}
        onChange={handleInputChange}
        placeholder="Category"
        className="w-full p-2 mb-2 border rounded"
        required
      />
      <textarea
        name="content"
        value={newResource.content}
        onChange={handleInputChange}
        placeholder="Resource Content or Embed Code"
        className="w-full p-2 mb-2 border rounded"
        required
      />
      <input
        type="url"
        name="url"
        value={newResource.url}
        onChange={handleInputChange}
        placeholder="Resource URL"
        className="w-full p-2 mb-2 border rounded"
        required
      />
      <button type="submit" className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600">
        Submit Resource
      </button>
    </form>
  );
};

export default ResourceSubmission;