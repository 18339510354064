import React, { useState, useEffect } from 'react';
import { UserPlus, Trash2, Edit, Mail, Phone, Users, PlusCircle } from 'lucide-react';

const ContactCard = ({ contact, onEdit, onDelete, onChangeTier, tiers }) => (
  <div className="bg-white p-4 rounded-lg shadow mb-4">
    <div className="flex justify-between items-start">
      <div>
        <h3 className="text-lg font-semibold">{contact.name}</h3>
        <p className="text-sm text-gray-600">{contact.relationship}</p>
      </div>
      <div className="space-x-2">
        <button onClick={() => onEdit(contact)} className="text-blue-600 hover:text-blue-800">
          <Edit size={18} />
        </button>
        <button onClick={() => onDelete(contact.id)} className="text-red-600 hover:text-red-800">
          <Trash2 size={18} />
        </button>
      </div>
    </div>
    <div className="mt-2 space-y-1">
      <p className="text-sm flex items-center"><Mail size={16} className="mr-2" /> {contact.email}</p>
      <p className="text-sm flex items-center"><Phone size={16} className="mr-2" /> {contact.phone}</p>
    </div>
    <div className="mt-3">
      <label className="block text-sm font-medium text-gray-700">Support Tier</label>
      <select
        value={contact.tierId}
        onChange={(e) => onChangeTier(contact.id, e.target.value)}
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
      >
        {tiers.map(tier => (
          <option key={tier.id} value={tier.id}>{tier.name}</option>
        ))}
      </select>
    </div>
  </div>
);

const TierCard = ({ tier, onEdit, onDelete }) => (
  <div className="bg-white p-4 rounded-lg shadow mb-4">
    <div className="flex justify-between items-start">
      <div>
        <h3 className="text-lg font-semibold">{tier.name}</h3>
        <p className="text-sm text-gray-600">{tier.description}</p>
      </div>
      <div className="space-x-2">
        <button onClick={() => onEdit(tier)} className="text-blue-600 hover:text-blue-800">
          <Edit size={18} />
        </button>
        <button onClick={() => onDelete(tier.id)} className="text-red-600 hover:text-red-800">
          <Trash2 size={18} />
        </button>
      </div>
    </div>
    <div className="mt-2">
      <h4 className="text-sm font-semibold">Shared Information:</h4>
      <ul className="text-sm text-gray-600 list-disc list-inside">
        {tier.sharedInfo.map((info, index) => (
          <li key={index}>{info}</li>
        ))}
      </ul>
    </div>
  </div>
);

const SupportNetworkManager = () => {
  const [contacts, setContacts] = useState([]);
  const [tiers, setTiers] = useState([]);
  const [isAddingContact, setIsAddingContact] = useState(false);
  const [isAddingTier, setIsAddingTier] = useState(false);
  const [editingContact, setEditingContact] = useState(null);
  const [editingTier, setEditingTier] = useState(null);
  const [newContact, setNewContact] = useState({
    name: '',
    relationship: '',
    email: '',
    phone: '',
    tierId: ''
  });
  const [newTier, setNewTier] = useState({
    name: '',
    description: '',
    sharedInfo: []
  });

  useEffect(() => {
    // TODO: Fetch contacts and tiers from backend
    // For now, we'll use mock data
    setContacts([
      { id: 1, name: 'Jane Doe', relationship: 'Sister', email: 'jane@example.com', phone: '(123) 456-7890', tierId: '1' },
      { id: 2, name: 'Dr. Smith', relationship: 'Therapist', email: 'dr.smith@example.com', phone: '(098) 765-4321', tierId: '2' }
    ]);
    setTiers([
      { id: '1', name: 'Close Family', description: 'Immediate family members', sharedInfo: ['Mood Data', 'Crisis Alerts'] },
      { id: '2', name: 'Medical Professionals', description: 'Doctors and therapists', sharedInfo: ['All Health Data', 'Treatment Progress'] }
    ]);
  }, []);

  const handleInputChange = (e, setterFunction) => {
    const { name, value } = e.target;
    setterFunction(prev => ({ ...prev, [name]: value }));
  };

  const handleSharedInfoChange = (info) => {
    setNewTier(prev => ({
      ...prev,
      sharedInfo: prev.sharedInfo.includes(info)
        ? prev.sharedInfo.filter(i => i !== info)
        : [...prev.sharedInfo, info]
    }));
  };

  const handleAddContact = () => {
    // TODO: Implement API call to add contact
    const contactToAdd = {
      ...newContact,
      id: Date.now() // Temporary ID, should be replaced with server-generated ID
    };
    setContacts(prev => [...prev, contactToAdd]);
    setNewContact({ name: '', relationship: '', email: '', phone: '', tierId: '' });
    setIsAddingContact(false);
  };

  const handleAddTier = () => {
    // TODO: Implement API call to add tier
    const tierToAdd = {
      ...newTier,
      id: Date.now().toString() // Temporary ID, should be replaced with server-generated ID
    };
    setTiers(prev => [...prev, tierToAdd]);
    setNewTier({ name: '', description: '', sharedInfo: [] });
    setIsAddingTier(false);
  };

  const handleEditContact = (contact) => {
    setEditingContact(contact);
    setNewContact(contact);
    setIsAddingContact(true);
  };

  const handleEditTier = (tier) => {
    setEditingTier(tier);
    setNewTier(tier);
    setIsAddingTier(true);
  };

  const handleUpdateContact = () => {
    // TODO: Implement API call to update contact
    setContacts(prev => prev.map(c => c.id === editingContact.id ? newContact : c));
    setNewContact({ name: '', relationship: '', email: '', phone: '', tierId: '' });
    setIsAddingContact(false);
    setEditingContact(null);
  };

  const handleUpdateTier = () => {
    // TODO: Implement API call to update tier
    setTiers(prev => prev.map(t => t.id === editingTier.id ? newTier : t));
    setNewTier({ name: '', description: '', sharedInfo: [] });
    setIsAddingTier(false);
    setEditingTier(null);
  };

  const handleDeleteContact = (id) => {
    // TODO: Implement API call to delete contact
    setContacts(prev => prev.filter(c => c.id !== id));
  };

  const handleDeleteTier = (id) => {
    // TODO: Implement API call to delete tier
    setTiers(prev => prev.filter(t => t.id !== id));
    // Also update contacts that were in this tier
    setContacts(prev => prev.map(c => c.tierId === id ? { ...c, tierId: '' } : c));
  };

  const handleChangeTier = (contactId, newTierId) => {
    // TODO: Implement API call to update contact's tier
    setContacts(prev => prev.map(c => c.id === contactId ? { ...c, tierId: newTierId } : c));
  };

  return (
    <div className="max-w-4xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold text-gray-800 mb-6">Support Network</h2>
      
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Support Tiers</h3>
        {tiers.map(tier => (
          <TierCard
            key={tier.id}
            tier={tier}
            onEdit={handleEditTier}
            onDelete={handleDeleteTier}
          />
        ))}
        {!isAddingTier && (
          <button
            onClick={() => setIsAddingTier(true)}
            className="mt-4 flex items-center text-blue-600 hover:text-blue-800"
          >
            <PlusCircle size={20} className="mr-2" />
            Add New Tier
          </button>
        )}
        {isAddingTier && (
          <div className="mt-4 border-t pt-4">
            <h4 className="text-lg font-semibold mb-2">{editingTier ? 'Edit Tier' : 'Add New Tier'}</h4>
            <div className="space-y-4">
              <input
                type="text"
                name="name"
                value={newTier.name}
                onChange={(e) => handleInputChange(e, setNewTier)}
                placeholder="Tier Name"
                className="w-full p-2 border rounded"
              />
              <input
                type="text"
                name="description"
                value={newTier.description}
                onChange={(e) => handleInputChange(e, setNewTier)}
                placeholder="Tier Description"
                className="w-full p-2 border rounded"
              />
              <div>
                <p className="mb-2 font-medium">Shared Information:</p>
                {['Mood Data', 'Crisis Alerts', 'Journal Entries', 'Treatment Progress'].map(info => (
                  <label key={info} className="flex items-center mt-2">
                    <input
                      type="checkbox"
                      checked={newTier.sharedInfo.includes(info)}
                      onChange={() => handleSharedInfoChange(info)}
                      className="mr-2"
                    />
                    {info}
                  </label>
                ))}
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  onClick={() => {
                    setIsAddingTier(false);
                    setEditingTier(null);
                    setNewTier({ name: '', description: '', sharedInfo: [] });
                  }}
                  className="px-4 py-2 border rounded text-gray-600 hover:bg-gray-100"
                >
                  Cancel
                </button>
                <button
                  onClick={editingTier ? handleUpdateTier : handleAddTier}
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                  {editingTier ? 'Update Tier' : 'Add Tier'}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <div>
        <h3 className="text-xl font-semibold mb-4">Contacts</h3>
        {contacts.map(contact => (
          <ContactCard
            key={contact.id}
            contact={contact}
            onEdit={handleEditContact}
            onDelete={handleDeleteContact}
            onChangeTier={handleChangeTier}
            tiers={tiers}
          />
        ))}
        {!isAddingContact && (
          <button
            onClick={() => setIsAddingContact(true)}
            className="mt-4 flex items-center text-blue-600 hover:text-blue-800"
          >
            <UserPlus size={20} className="mr-2" />
            Add New Contact
          </button>
        )}
        {isAddingContact && (
          <div className="mt-4 border-t pt-4">
            <h4 className="text-lg font-semibold mb-2">{editingContact ? 'Edit Contact' : 'Add New Contact'}</h4>
            <div className="space-y-4">
              <input
                type="text"
                name="name"
                value={newContact.name}
                onChange={(e) => handleInputChange(e, setNewContact)}
                placeholder="Name"
                className="w-full p-2 border rounded"
              />
              <input
                type="text"
                name="relationship"
                value={newContact.relationship}
                onChange={(e) => handleInputChange(e, setNewContact)}
                placeholder="Relationship"
                className="w-full p-2 border rounded"
              />
              <input
                type="email"
                name="email"
                value={newContact.email}
                onChange={(e) => handleInputChange(e, setNewContact)}
                placeholder="Email"
                className="w-full p-2 border rounded"
              />
              <input
                type="tel"
                name="phone"
                value={newContact.phone}
                onChange={(e) => handleInputChange(e, setNewContact)}
                placeholder="Phone"
                className="w-full p-2 border rounded"
              />
              <select
                name="tierId"
                value={newContact.tierId}
                onChange={(e) => handleInputChange(e, setNewContact)}
                className="w-full p-2 border rounded"
              >
                <option value="">Select a Tier</option>
                {tiers.map(tier => (
                  <option key={tier.id} value={tier.id}>{tier.name}</option>
                ))}
              </select>
              <div className="flex justify-end space-x-2">
                <button
                  onClick={() => {
                    setIsAddingContact(false);
                    setEditingContact(null);
                    setNewContact({ name: '', relationship: '', email: '', phone: '', tierId: '' });
                  }}
                  className="px-4 py-2 border rounded text-gray-600 hover:bg-gray-100"
                >
                  Cancel
                </button>
                <button
                  onClick={editingContact ? handleUpdateContact : handleAddContact}
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                  {editingContact ? 'Update Contact' : 'Add Contact'}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SupportNetworkManager;